import React, { useEffect } from 'react';
import _ from 'lodash';
import { useApiGet, useApiLoading } from 'react-reqq';
import Helmet from 'react-helmet';
import { showModal } from 'modules/common/components/AlertModal';
import { useProfile } from 'modules/auth/hooks';
import { formatDate, renderStatus } from 'modules/common/helper';
import Pagination from 'modules/common/components/Pagination';
import ConfirmAlertRemarks from 'modules/common/components/ConfirmAlertRemarks';
import GenericTable from 'modules/common/components/GenericTable';
import TransactionItemViewModal from '../modals/TransactionItemViewModal';
import TransactionItemApproveModal from '../modals/TransactionItemApproveModal';
import TransactionItemResendModal from '../modals/TransactionItemResendModal';
import * as actions from '../actions';
import * as c from '../constants';

const PAGE_SIZE = 10;

function TransactionListContainer() {
  const profile = useProfile();
  const { list, pager } = useApiGet(c.LIST_TRANSACTION);
  const isLoading = useApiLoading(c.LIST_TRANSACTION, 'get');
  const isLoadingApprove = useApiLoading(c.APPROVE_TRANSACTION, 'post');
  const isLoadingReject = useApiLoading(c.REJECT_TRANSACTION, 'post');
  const isLoadingResend = useApiLoading(c.RESEND_TRANSACTION, 'post');

  const handleOnUpdate = () => {
    actions.listTransactions({ page: (_.get(pager, 'current_page') || 1), size: PAGE_SIZE });
  };
  const handleChangePage = (page) => {
    actions.listTransactions({ page, size: PAGE_SIZE });
  };

  const handleOpenViewModal = (item) => {
    showModal({
      title: 'Transaction Details',
      className: 'modal-large',
      content: (close) => (
        <TransactionItemViewModal
          onClose={close}
          data={item}
          onUpdate={handleOnUpdate}
        />
      ),
    });
  };

  const handleOpenApproveModal = (item) => {
    showModal({
      title: 'Transaction Approval',
      className: 'modal-small',
      content: (close) => (
        <TransactionItemApproveModal
          onClose={close}
          data={item}
          onUpdate={handleOnUpdate}
        />
      ),
    });
  };

  const handleReject = (item, msg, callback) => {
    const args = {
      remarks: msg,
      rejected_by: profile.email,
      disbursement_batch: item.batch_number,
    };

    if (isLoadingReject) return;
    actions.rejectTransaction(args, () => {
      handleOnUpdate();
      callback();
    });
  };

  const handleOpenRejectModal = (item) => {
    showModal({
      title: '',
      withCloseButton: false,
      className: 'modal-small',
      content: (close) => (
        <ConfirmAlertRemarks
          onClose={close}
          data={item}
          onYes={handleReject}
          disclaimer="The payroll items in this batch will be released and will be allowed to be re-assigned to other batch"
          label="Are you sure you want to reject this batch?"
        />
      ),
    });
  };

  const handleResend = (item) => {
    showModal({
      title: 'Resend Transaction',
      className: 'modal-small',
      content: (close) => (
        <TransactionItemResendModal
          onClose={close}
          data={item}
          onUpdate={handleOnUpdate}
        />
      ),
    });
  };

  useEffect(() => {
    actions.listTransactions({ page: 1, size: PAGE_SIZE });
  }, []);
  const renderAction = (item) => (
    <>
      <button type="button" className="btn-blank text-secondary mr-2" onClick={() => handleOpenViewModal(item)}>View</button>
      {item.status === 'Pending' && (
        <>
          <button type="button" className="btn-blank text-success mr-2" onClick={() => handleOpenApproveModal(item)}>Approve</button>
          <button type="button" className="btn-blank text-danger" onClick={() => handleOpenRejectModal(item)}>Decline</button>
        </>
      )}
      {item.status === 'Post Incomplete' && (
        <button type="button" className="btn-blank text-success" onClick={() => handleResend(item)}>Resend</button>
      )}
    </>
  );

  return (
    <>
      <Helmet>
        <title>Disbursement Transaction - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <div className="container-card">
        <div className="row mb-4">
          <div className="col-12">
            <h3 className="main-title">Disbursement Transaction</h3>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <GenericTable
              isLoading={isLoading || isLoadingApprove || isLoadingReject || isLoadingResend}
              format={[
                {
                  key: (item) => <p className="text">{_.get(item, 'payroll_name')}</p>,
                  label: 'PAYROLL NAME',
                },
                {
                  key: (item) => <p className="text">{_.get(item, 'batch_number')}</p>,
                  label: 'DISBURSEMENT BATCH',
                },
                {
                  key: (item) => <p className="text">{renderStatus(_.get(item, 'status'))}</p>,
                  label: 'STATUS',
                },
                {
                  key: (item) => <p className="text">{formatDate(_.get(item, 'created_date'))}</p>,
                  label: 'DATE CREATED',
                },
                {
                  key: (item) => renderAction(item),
                  width: '240px',
                  label: 'ACTION',
                },
              ]}
              data={list}
            />
            <div className="mt-3">
              <Pagination data={pager} onChange={handleChangePage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

TransactionListContainer.propTypes = {
};

export default TransactionListContainer;
