import { req } from 'react-reqq';
import _ from 'lodash';
import { ToastSuccess } from 'modules/common/components/toast';
import * as c from './constants';

export const listTransactions = (params, callback) => req.get({
  key: c.LIST_TRANSACTION,
  url: '/list-disbursement-batch',
  params,
  transform: (res) => ({
    list: (_.get(res, 'data.data.content') || []).map((item, i) => ({ id: i, ...item })),
    pager: _.get(res, 'meta.pagination') || {},
  }),
  onSuccess: () => {
    if (!_.isNil(callback)) {
      callback();
    }
  },
});

export const getTransactionItem = (type, id, callback) => req.get({
  key: c.TRANSACTION_ITEM,
  url: `/disbursement-batch/${type}/${id}`,
  transform: (res) => ({
    list: (_.get(res, 'data.data') || []).map((item, i) => ({ id: i, ...item })),
  }),
  onSuccess: () => {
    if (!_.isNil(callback)) {
      callback();
    }
  },
});

export const approveTransaction = (params, payload, callback) => req.post({
  key: c.APPROVE_TRANSACTION,
  url: `/cash-disbursement/${params.email}/${params.batch_no}/${params.notif_mode}/${params.disbursement_mode}`,
  payload,
  onSuccess: () => {
    ToastSuccess('Successfully Approved!');
    if (!_.isNil(callback)) {
      callback();
    }
  },
});

export const rejectTransaction = (payload, callback) => req.post({
  key: c.REJECT_TRANSACTION,
  url: '/reject-disbursement-batch',
  payload,
  onSuccess: () => {
    ToastSuccess('Successfully Rejected!');
    if (!_.isNil(callback)) {
      callback();
    }
  },
});

export const resendTransaction = (params, payload, callback) => req.post({
  key: c.RESEND_TRANSACTION,
  url: `/repost-cash-disbursement/${params.email}/${params.batch_no}/${params.notif_mode}/${params.disbursement_mode}`,
  payload,
  onSuccess: () => {
    ToastSuccess('Successfully Resent!');
    if (!_.isNil(callback)) {
      callback();
    }
  },
});
