/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { ToastError } from 'modules/common/components/toast';

const ellipsisLeft = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '70%',
  direction: 'rtl',
  textAlign: 'left',
};

const renderType = (type) => {
  const imgF = ['image/jpeg', 'image/jpg', 'image/png'];
  const detectWhatToRender = () => {
    if (imgF.indexOf(type) > -1) {
      return 'img';
    }
    return 'application';
  };

  try {
    const dispatchRender = {
      img: () => <i className="fas fa-file-image fa-3x" />,
      application: () => {
        const dipatchApplicationType = {
          'application/pdf': () => <i className="fas fa-file-pdf fa-3x" />,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': () => <i className="fas fa-file-excel fa-3x" />,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': () => <i className="fas fa-file-word fa-3x" />,
          'text/csv': () => <i className="fas fa-file-csv fa-3x" />,
          default: () => <i className="fas fa-file-alt fa-3x" />,
        };
        return dipatchApplicationType[type]
          ? dipatchApplicationType[type]()
          : dipatchApplicationType.default();
      },
      default: () => <i claclassNamess="fas fa-file-alt fa-3x" />,
    };

    return dispatchRender[detectWhatToRender()]
      ? dispatchRender[detectWhatToRender()]()
      : dispatchRender.default();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Somthing went wrong!');
    return null;
  }
};

const ProgressBar = React.memo(({
  data, progress, cancelRequest, removeItem,
}) => (
  <div className="card rounded-sm bg-light border mb-2">
    <div className="card-body p-2">
      <div className="container-fluid">
        <div className="row px-0 align-items-center">
          <div className="col-2 pr-0 pl-2">
            <div className="border-right border-dark">
              {renderType(_.get(data, 'file.type', ''))}
            </div>
          </div>
          <div className="col-10">
            <div className="d-flex align-items-center justify-content-between">
              <div className="mr-auto" style={_.get(data, 'file.name').length > 18 ? ellipsisLeft : {}}>
                <small className="font-weight-bold text-ellipsis">{_.get(data, 'file.name') || '--'}</small>
              </div>
              <div className="ml-auto">
                {(!data.progress || progress === 100)
                  ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        removeItem(data.id);
                      }}
                      type="button"
                      className="btn btn-link text-muted p-0 text-muted"
                    >
                      <i className="fas fa-trash" />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        cancelRequest(data.id);
                      }}
                      className="btn-delete btn btn-link text-muted p-0"
                    >
                      <i className="fas fa-times" />
                    </button>
                  )}
              </div>
            </div>
            <div className="w-100">
              <div
                className="d-flex justify-content-between text-muted mb-1"
                style={{
                  fontSize: '11px',
                }}
              >
                {data.progress
                  ? (
                    <>
                      <span>
                        {progress !== 100 ? 'UPLOADING' : 'COMPLETED'}
                      </span>
                      <span>{progress}%</span>
                    </>
                  ) : (
                    <>
                      <span>CANCELED</span>
                      {data.isLarge && <small className="text-danger">Unable to upload.</small>}
                    </>
                  )}
              </div>
              <div
                className={`progress ${!data.progress ? 'bg-danger' : ''}`}
                style={{
                  height: '10px',
                }}
              >
                <div
                  className={`progress-bar ${data.progress ? 'bg-success' : 'bg-transparent'}`}
                  role="progressbar"
                  style={{
                    width: `${progress}%`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
));

ProgressBar.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  progress: PropTypes.number.isRequired,
  cancelRequest: PropTypes.instanceOf(Function).isRequired,
  removeItem: PropTypes.instanceOf(Function).isRequired,
};

const drapzoneStyle = {
  cursor: 'pointer',
  borderStyle: 'dashed',
  outline: 'none',
};

const baseURL = process.env.REACT_APP_END_POINT;

const xhr = new Array([]);

const uploadFile = (form, i, onProgress, onErrCallback) => new Promise((resolve, reject) => {
  try {
    const newFormData = new FormData();
    newFormData.append('file', form);

    xhr[i] = new XMLHttpRequest();
    xhr[i].timeout = 300000;
    xhr[i].addEventListener('timeout', () => resolve({
      status: 400,
      message: 'Request Timeout, Please try again.',
    }));

    const url = `${baseURL}/upload`;
    xhr[i].open('POST', url);
    const token = sessionStorage.getItem('token');
    xhr[i].setRequestHeader('Authorization', `${token}`);
    // xhr`[i].setRequestHeader('Content-Type', 'multipart/form-data');
    // xhr[i].setRequestHeader('Content-Type', 'multipart/form-data; boundary=--XXXCASHAIDXXX');
    xhr[i].onload = () => {
      try {
        const jsonResponse = JSON.parse(xhr[i].response);

        const response = {
          status: xhr[i].status, // jsonResponse.status,
          data: jsonResponse,
        };
        if (_.get(response, 'status') === 401) {
          const { message } = response.data;
          ToastError(message);
          return;
        }

        const data = {
          response,
          file: _.get(response, 'data.data.file'),
          id: i,
        };

        resolve(data);
      } catch (err) {
        reject(err);
      }
    };

    xhr[i].onerror = () => {
      const args = {
        file: form,
        id: i,
        progress: false,
        isLarge: true,
      };
      onErrCallback(args);
    };

    xhr[i].onabort = () => {
      const args = {
        file: form,
        id: i,
        progress: false,
      };
      onErrCallback(args);
    };

    if (xhr[i].upload) {
      xhr[i].upload.onprogress = (evt) => {
        if (evt.lengthComputable) {
          const progress = Math.ceil(((evt.loaded) / evt.total) * 100);
          if (onProgress) { onProgress({ [i]: progress }); }
        }
      };
    }

    xhr[i].send(newFormData);
  } catch (err) {
    reject(err);
  }
});

function FormUploadFile({
  isMultiple, fileType, maxSize, onChange, isImageOnly,
  caption, disabled, className, customButton, modalTitle,
}) {
  const [showModal, setShowModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [rawFileData, setRawFileData] = useState([]);
  const [progressBars, setProgressBars] = useState({});
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);

  const handleOnConfirm = (e) => {
    e.preventDefault();
    setShowModal(false);
    // onChange(files.map((item) => item.file));
    onChange(files);
    setFiles([]);
    setRawFileData([]);
    setProgressBars({});
  };

  const handleOnSetModal = (value) => (e) => {
    e.preventDefault();
    setShowModal(value);
    if (!value) {
      setFiles([]);
      setRawFileData([]);
      setProgressBars({});
      setIsFileTooLarge(false);
    }
  };

  const onProgress = (progress) => {
    setProgressBars((prevState) => ({ ...prevState, ...progress }));
  };

  const errCallback = (data) => {
    setRawFileData((prevState) => prevState.map((item) => {
      if (item.id === data.id) {
        const newItem = {
          ...item,
          ...data,
        };
        return newItem;
      }
      return item;
    }));
  };

  const handleOnCancelRequest = (id) => {
    xhr[id].abort();
  };

  const handleOnRemoveItem = (id) => {
    setRawFileData((prevState) => (prevState.filter((item) => item.id !== id)));
    setFiles((prevState) => (prevState.filter((item) => item.id !== id)));
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setIsFileTooLarge(true);
    }

    _.forEach(acceptedFiles, (v) => {
      const k = uuid();

      uploadFile(v, k, onProgress, errCallback).then((data) => {
        if (!data) {
          // setIsFileTooLarge(true);
          return;
        }
        if (isMultiple) {
          setFiles((prevState) => ([...prevState, data]));
          return;
        }
        setFiles([data]);
      });

      const data = {
        file: v,
        id: k,
        progress: true,
      };

      if (isMultiple) {
        setRawFileData((prevState) => ([...prevState, data]));
        return;
      }
      setRawFileData([data]);
    });
  }, [files]);

  useEffect(() => {
    setFiles([]);
    setRawFileData([]);
    setProgressBars({});
    setIsFileTooLarge(false);
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, []);

  const {
    getRootProps, getInputProps, isDragActive,
  } = useDropzone({
    onDrop,
    maxSize: maxSize * 1048576,
  });

  return (
    <>
      <button
        type="button"
        className={className}
        onClick={handleOnSetModal(true)}
        disabled={disabled}
      >
        {!customButton
          ? (
            <>
              <i className="fa fa-upload mr-1" />
              <span className="text">Upload</span>
            </>
          ) : customButton()}
      </button>
      <div className={`modal ${showModal ? 'd-block' : 'd-none'}`}>
        <div className={`modal-dialog ${isMultiple ? 'modal-lg' : 'modal-md'} modal-dialog-centered`}>
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between">
              <h5 className="m-0 p-0">{modalTitle}</h5>
              <button type="button" className="close" onClick={handleOnSetModal(false)}>
                <span>&times;</span>
                <span className="sr-only">Close</span>
              </button>
            </div>
            <div className="modal-body">
              {!isMultiple
                ? (
                  <>
                    <div className="drapzone-container bg-light text-center">
                      <div
                        style={drapzoneStyle}
                        {...getRootProps()}
                        className="p-3"
                      >
                        <h3 className="mt-0">
                          <i className={`fas ${isImageOnly ? 'fa-image' : 'fa-file-upload'} fa-4x`} />
                        </h3>
                        <input
                          {...getInputProps()}
                          multiple={isMultiple}
                          accept={fileType}
                        />
                        <div>
                          {
                            isDragActive
                              ? 'Drop your files here'
                              : 'Click me or drag a file to upload!'
                          }
                        </div>
                        {isFileTooLarge && (
                          <div className="font-weight-bold text-warning">
                            {isMultiple
                              ? 'Some file is to large to upload or file format is invalid.'
                              : 'File is to large to upload or invalid format.'}
                          </div>
                        )}
                        <small>
                          {caption}
                        </small>
                      </div>
                    </div>
                    <div className="mt-3">
                      {rawFileData.map((item) => (
                        <ProgressBar
                          key={item.id}
                          data={item}
                          progress={progressBars[item.id] || 0}
                          cancelRequest={handleOnCancelRequest}
                          removeItem={handleOnRemoveItem}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="row h-100">
                    <div className="col-5 h-100">
                      <div className="drapzone-container bg-light text-center">
                        <div
                          style={drapzoneStyle}
                          {...getRootProps()}
                          className="p-3"
                        >
                          <h3 className="mt-0">
                            <i className={`fas ${isImageOnly ? 'fa-image' : 'fa-file-upload'} fa-4x`} />
                          </h3>
                          <input
                            {...getInputProps()}
                            multiple={isMultiple}
                            accept={fileType}
                          />
                          <div>
                            {
                              isDragActive
                                ? 'Drop your files here'
                                : 'Click me or drag a file to upload!'
                            }
                          </div>
                          {isFileTooLarge && (
                            <div className="font-weight-bold text-warning">
                              {isMultiple
                                ? 'Some file is to large to upload or file format is invalid.'
                                : 'File is to large to upload or invalid format.'}
                            </div>
                          )}
                          <small>
                            {caption}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-7 h-100">
                      {rawFileData.length > 0
                        ? (
                          <div
                            className="d-flex"
                            style={{ height: '250px' }}
                          >
                            <div className="box-fit-wrapper h-100 flex-grow-1">
                              <div className="box-fit-overflow">
                                {rawFileData.map((item) => (
                                  <ProgressBar
                                    key={item.id}
                                    data={item}
                                    progress={progressBars[item.id] || 0}
                                    cancelRequest={handleOnCancelRequest}
                                    removeItem={handleOnRemoveItem}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="card rounded-sm bg-light border-light" style={{ height: '250px' }}>
                            <div className="card-body d-flex justify-content-center align-items-center">
                              <h3 className="text-muted">No attach found.</h3>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                )}
              <div className="w-100 text-center">
                {isMultiple
                  ? (
                    <button
                      type="button"
                      onClick={handleOnConfirm}
                      disabled={_.isEmpty(files)}
                      className="btn btn-sm btn-success mr-1"
                    >
                      <span><i className="fas fa-save" /> Save</span>
                    </button>
                  ) : (
                    <>
                      {_.isEmpty(_.get(files, '0.response.data.resource') || '')
                        ? (
                          <>
                            {!_.isEmpty(_.get(files, '0.response.data.message') || '') && (
                              <div className="disclaimer">
                                {_.get(files, '0.response.data.message') || ''}
                              </div>
                            )}
                            <button
                              type="button"
                              onClick={(handleOnConfirm)}
                              disabled
                              className="btn btn-sm btn-disabled mr-1"
                            >
                              Confirm
                            </button>
                          </>
                        ) : (
                          <button
                            type="button"
                            onClick={handleOnConfirm}
                            disabled={_.isEmpty(files)}
                            className="btn btn-sm btn-success mr-1"
                          >
                            Confirm
                          </button>
                        )}
                    </>
                  )}
                {isMultiple && (
                  <button
                    type="button"
                    onClick={handleOnSetModal(false)}
                    className="btn-sm btn-danger ml-1"
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        key="modal-backdrop"
        className={`modal-backdrop ${showModal ? 'show' : ''}`}
        style={{
          display: showModal ? 'block' : 'none',
        }}
      />
    </>
  );
}

FormUploadFile.defaultProps = {
  modalTitle: 'Upload File',
  isMultiple: false,
  isImageOnly: true,
  disabled: false,
  customButton: false,
  maxSize: 2,
  fileType: 'image/*, application/*',
  caption: 'Maximum size of image is 1MB and a minimum resolution of 380x251 pixels. JPG.PNG.',
  className: 'btn btn-success btn-sm btn-block text-center',
};

FormUploadFile.propTypes = {
  onChange: PropTypes.instanceOf(Function).isRequired,
  modalTitle: PropTypes.string,
  caption: PropTypes.string,
  fileType: PropTypes.string,
  className: PropTypes.string,
  isMultiple: PropTypes.bool,
  isImageOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSize: PropTypes.number,
  customButton: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.instanceOf(Function),
  ]),
};

export default React.memo(FormUploadFile);
