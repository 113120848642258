import React from 'react';
import Helmet from 'react-helmet';
import { useProfile } from 'modules/auth/hooks';
import DashboardContentMaker from './DashboardContentMaker';
import DashboardContentApprover from './DashboardContentApprover';

function DashboardContainer() {
  const profile = useProfile();
  const renderBody = () => {
    if (profile.user_type === 'MAKER') {
      return <DashboardContentMaker />;
    }
    return <DashboardContentApprover />;
  };

  return (
    <>
      <Helmet>
        <title>Dashboard - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      {renderBody()}
    </>
  );
}

DashboardContainer.propTypes = {
};

export default DashboardContainer;
