/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

function FormInput({
  name, onChange, label, value, required, ...rest
}) {
  const handleChange = ({ target }) => {
    onChange((prev) => ({ ...prev, [name]: target.value }));
  };
  return (
    <>
      {label && <small>{label} {required && <span className="input-required-text">(Required)</span>}</small>}
      <input
        className="form-control"
        onChange={handleChange}
        value={value || ''}
        required={required}
        {...rest}
      />
    </>
  );
}

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
};

FormInput.defaultProps = {
  label: '',
  required: false,
};

export default FormInput;
