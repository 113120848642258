import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useApiGet, useApiLoading } from 'react-reqq';
import Helmet from 'react-helmet';
import { showModal } from 'modules/common/components/AlertModal';
import Pagination from 'modules/common/components/Pagination';
import UploadCSVContainer from 'modules/upload-csv/containers/UploadCSVContainer';
import GenericTable from 'modules/common/components/GenericTable';
import FormInputFilterKeyword from 'modules/common/form/FormInputFilterKeyword';
import ConfirmAlert from 'modules/common/components/ConfirmAlert';
import CashAidReceiverUpdateItemModal from '../components/CashAidReceiverUpdateItemModal';
import * as actions from '../actions';
import * as c from '../constants';

const PAGE_SIZE = 10;
function CashAidReceiverContainer() {
  const [filter, setFilter] = useState('');
  const [isUsingFilter, setIsUsingFilter] = useState(false);
  const { list, pager } = useApiGet(c.LIST_PAYROLL);
  const isLoading = useApiLoading(c.LIST_PAYROLL, 'get');
  const isLoadingUpdate = useApiLoading(c.PAYROLL_ITEM, 'post');
  const isLoadingRemove = useApiLoading(c.PAYROLL_ITEM, 'remove');

  const handleChangePage = (page) => {
    if (!isUsingFilter || filter === '') {
      actions.listPayroll({ page, per_page: PAGE_SIZE });
      return;
    }
    actions.filterPayroll(filter, { page, size: PAGE_SIZE });
  };

  const handleUpdateList = () => {
    if (!isUsingFilter || filter === '') {
      actions.listPayroll({ page: 1, per_page: PAGE_SIZE });
      return;
    }
    actions.filterPayroll(filter, { page: 1, size: PAGE_SIZE });
  };

  const handleSubmitFilter = () => {
    if (filter === '') {
      handleUpdateList();
      setIsUsingFilter(false);
      return;
    }
    setIsUsingFilter(true);
    actions.filterPayroll(filter, { page: 1, size: PAGE_SIZE });
  };

  const handleOpenUpdateItemModal = (item) => {
    showModal({
      title: 'Edit Cash Aid Receiver',
      className: 'modal-medium',
      content: (close) => (
        <CashAidReceiverUpdateItemModal
          onClose={close}
          onUpdate={handleUpdateList}
          data={item}
        />
      ),
    });
  };

  const handleRemoveItem = (item) => {
    showModal({
      title: '',
      withCloseButton: false,
      className: 'modal-small',
      content: (close) => (
        <ConfirmAlert
          onClose={close}
          onYes={() => {
            actions.removePayrollItem(item.id, () => {
              handleUpdateList();
              close();
            });
          }}
          label="Are you sure you want to remove this item?"
        />
      ),
    });
  };

  const renderAction = (item) => (
    <>
      <button
        type="button"
        className="btn-blank text-secondary"
        onClick={() => handleOpenUpdateItemModal(item)}
      >
        Edit
      </button>
      <button
        type="button"
        className="btn-blank text-danger"
        onClick={() => handleRemoveItem(item)}
      >
        Remove
      </button>
    </>
  );

  useEffect(() => {
    handleUpdateList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cash Aid Receiver - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <div className="container-card">
        <div className="row mb-4">
          <div className="col-12 d-flex">
            <h3 className="main-title">Cash Aid Receivers</h3>
            <UploadCSVContainer
              onCreateDisbursement={handleUpdateList}
              onUploadCSV={handleUpdateList}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <FormInputFilterKeyword
              name="keyword"
              label="Filter"
              onChange={setFilter}
              placeholder="Enter Name"
              value={filter}
              onSubmit={handleSubmitFilter}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <GenericTable
              isLoading={isLoading || isLoadingUpdate || isLoadingRemove}
              format={[
                {
                  key: (item) => <p className="text">{_.get(item, 'id')}</p>,
                  label: 'ID No.',
                },
                {
                  key: (item) => <p className="text">{_.get(item, 'attributes.subscriber_name')}</p>,
                  label: 'SUBSCRIBER NAME',
                },
                {
                  key: (item) => <p className="text">{_.get(item, 'attributes.mobile_number')}</p>,
                  label: 'MOBILE NO',
                },
                {
                  key: (item) => <p className="text">{_.get(item, 'attributes.amount')}</p>,
                  label: 'AMOUNT',
                },
                {
                  key: (item) => <p className="text">{_.get(item, 'attributes.status_code')}</p>,
                  label: 'STATUS CODE',
                },
                {
                  key: (item) => renderAction(item),
                  label: 'ACTION',
                  width: '155px',
                },
              ]}
              data={list}
            />
            <div className="mt-3">
              <Pagination data={pager} onChange={handleChangePage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

CashAidReceiverContainer.propTypes = {
};

export default CashAidReceiverContainer;
