import React, { useState } from 'react';
// import _ from 'lodash';
import PropTypes from 'prop-types';
import FormInput from 'modules/common/form/FormInput';
import * as actions from '../actions';
// import * as c from '../constants';

function CreatePayrollItemModal({ onClose }) {
  const [form, setForm] = useState({});


  const handleOnSubmit = (e) => {
    e.preventDefault();
    actions.submitPayrollItem(form, onClose);
  };

  return (
    <form className="modal-body" onSubmit={handleOnSubmit}>
      <div className="row mb-4">
        <div className="col-12">
          <label className="font-weight-bold">Transaction Information</label>
        </div>
        <div className="col-4">
          <FormInput
            name="employee_id"
            label="Employee ID"
            onChange={setForm}
            value={form.employee_id || ''}
            required
          />
        </div>
        <div className="col-4">
          <FormInput
            name="amount"
            label="Amount"
            type="number"
            onChange={setForm}
            value={form.amount || ''}
            required
          />
        </div>
        <div className="col-4">
          <FormInput
            name="account_number"
            label="Account Number"
            onChange={setForm}
            value={form.account_number || ''}
            required
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12">
          <label className="font-weight-bold">Personal Information</label>
        </div>
        <div className="col-4">
          <FormInput
            name="first_name"
            label="First Name"
            onChange={setForm}
            value={form.first_name || ''}
            required
          />
        </div>
        <div className="col-4">
          <FormInput
            name="middle_name"
            label="Middle Name"
            onChange={setForm}
            value={form.middle_name || ''}
          />
        </div>
        <div className="col-4">
          <FormInput
            name="last_name"
            label="Last Name"
            onChange={setForm}
            value={form.last_name || ''}
            required
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-4">
          <FormInput
            name="cellphone"
            type="number"
            label="Contact No."
            onChange={setForm}
            value={form.cellphone || ''}
            required
          />
        </div>
        <div className="col-8">
          <FormInput
            name="address"
            label="Address"
            onChange={setForm}
            value={form.address || ''}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <button type="submit" className="btn btn-secondary">Submit</button>
        </div>
      </div>
    </form>
  );
}

CreatePayrollItemModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
};

export default CreatePayrollItemModal;
