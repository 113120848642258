import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import DOMPurify from 'dompurify';
import { useProfile } from 'modules/auth/hooks';

export const delay = (t = 1000) => new Promise((r) => setTimeout(r, t));

export const diffObject = (rObject, rBase) => {
  const changes = (object, base) => _.transform(object, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      result[key] = value; // eslint-disable-line
    }
  });
  return changes(rObject, rBase);
};

export const deepDiffObject = (rObject, rBase) => {
  const changes = (object, base) => _.transform(object, (result, value, key) => {
    const a = (typeof value === 'string' || typeof value === 'number') ? `${value}` : value;
    const b = (typeof base[key] === 'string' || typeof base[key] === 'number') ? `${base[key]}` : base[key];
    if (!_.isEqual(a, b)) {
      result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value; // eslint-disable-line
    }
  });
  return changes(rObject, rBase);
};

export const limitString = (str, max_length, ellipsis = '…') => (((`${str}`).length > max_length)
  ? `${(`${str || ''}`).substring(0, max_length)}${ellipsis}`
  : str
);

export const parseNumber = (str, default_value = false) => {
  const v = parseFloat((`${str}`).replace(/,/g, ''));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(v)) return (typeof default_value !== 'boolean') ? default_value : str;
  return v;
};

export const formatNumber = (v, decimal = 2) => {
  try {
    const n = parseNumber(v);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(n)) return v;
    return n.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  } catch (err) {
    return v;
  }
};

export const generate = (length = 10) => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; i += 1) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const replaceParam = (match, params) => {
  try {
    const arr = match.path.split('/');
    const arr2 = window.location.pathname.split('/');
    let url = '';

    for (let i = 0; i < arr2.length; i += 1) {
      const x = arr[i] || arr2[i];
      if (x) {
        const key = x.replace(':', '').replace('?', '');
        const newValue = params[key];
        url = `${url}/${newValue || x}`;
      }
    }

    return url;
  } catch (err) {
    return '/';
  }
};

export const replaceUrl = (base, newUrl) => {
  const arr1 = base.split('/');
  const arr2 = newUrl.split('/');
  return arr1.map((x, i) => arr2[i] || x).join('/');
};

export const renderPercentage = (current, previous) => {
  if (current === 'now' || (typeof current !== 'number' && typeof previous !== 'number' && !current && !previous)) return <span className="badge badge-info">NOW</span>;
  if (previous === 0) return null;
  const percentage = ((current - previous) / previous) * 100;
  const percentage_string = formatNumber(percentage);
  const p = percentage > 999
    ? '+999%'
    : `${percentage_string}%`;
  if (percentage < 0) return <span className="badge badge-danger" title={percentage_string}><i className="fa fa-caret-down" />{p}</span>;
  if (percentage > 0) return <span className="badge badge-success" title={percentage_string}><i className="fa fa-caret-up" />{p}</span>;
  return null;
};

export const useACL = (permission) => {
  const auth = useProfile();
  const [hasAccess, setHasAccess] = React.useState(false);
  React.useEffect(() => {
    setHasAccess((auth.permissions || []).indexOf(permission) > -1);
  }, []);
  return hasAccess;
};

export const loadAPI = (url, onLoad, onReject) => {
  try {
    const tag = document.createElement('script');
    tag.type = 'text/javascript';
    tag.async = false;

    const handleLoad = () => {
      onLoad();
    };
    const handleReject = () => {
      onReject();
    };

    tag.addEventListener('load', handleLoad);
    tag.addEventListener('error', handleReject);
    tag.src = url;
    document.getElementsByTagName('body')[0].appendChild(tag);
  } catch (err) {
    console.error(err); // eslint-disable-line
    onReject(err);
  }
};

export const renderDangerous = (content) => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
);

export const renderColor = (color) => (
  <span className="label rounded" style={{ backgroundColor: color, width: 50, display: 'block' }}>&nbsp;</span>
);

export const removeNull = (obj) => _.omitBy(obj, (x) => typeof x === 'undefined' || x === null);

export const renderStatus = (status) => {
  switch (status.toUpperCase()) {
    case 'PENDING':
      return <span className="status-pill pending">Pending</span>;

    // PROCESSING
    case 'SENT FOR POSTING':
      return <span className="status-pill processing">Sent for Posting</span>;

    // WAITING
    case 'POST READY FOR PAYOUT':
      return <span className="status-pill waiting">Ready for Payout</span>;
    case 'READY FOR SENDING':
      return <span className="status-pill waiting">Ready for Sending</span>;
    case 'POST INCOMPLETE':
      return <span className="status-pill waiting">Incomplete</span>;

    // FAILED
    case 'DECLINED':
      return <span className="status-pill rejected">Declined</span>;
    case 'REJECTED':
      return <span className="status-pill rejected">Rejected</span>;

    // SUCCESS
    case 'APPROVED':
      return <span className="status-pill approved">Approved</span>;
    case 'SUBMITTED':
      return <span className="status-pill approved">Submitted</span>;
    case 'POST STARTED':
      return <span className="status-pill approved">Started</span>;
    case 'COMPLETED':
      return <span className="status-pill approved">Completed</span>;
    case 'CLAIMED':
      return <span className="status-pill approved">Claimed</span>;

    default:
      return <span className="status-pill pending">{status}</span>;
  }
};

export const formatDate = (date) => {
  if (!date) return '-';
  const d = new Date(date);
  if (d.toString() === 'Invalid Date') return '-';
  return moment(d).format('LLL');
};
