import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useApiLoading } from 'react-reqq';
import Logo from 'assets/img/logo-login.svg';
import * as actions from '../actions';
import * as c from '../constants';

function Login() {
  const [form, setForm] = useState({ email: '', password: '' });
  const isLoading = useApiLoading(c.LOGIN, 'post');
  const handleChangeInput = (key) => ({ target }) => {
    setForm({ ...form, [key]: target.value });
  };
  const handleLogin = (e) => {
    e.preventDefault();
    actions.login(form);
  };

  return (
    <>
      <Helmet>
        <title>Login - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <div className="login-container">
        <div className="login-form">
          <form onSubmit={handleLogin}>
            <div className="section-logo">
              <img alt="logo" src={Logo} />
            </div>
            <div className="section-title text-center">
              <h1>Sign In</h1>
              <label className="subtitle">
                Sign in to CDP Admin Portal
              </label>
            </div>
            <div className="login-box">
              <div className="form-group mb-2">
                <div className="floating-form w-100">
                  <input
                    className="floating-input form-control"
                    name="email"
                    type="email"
                    placeholder="Username"
                    value={form.email}
                    autoComplete="off"
                    onChange={handleChangeInput('email')}
                    required
                  />
                  <label className="floating-label" data-content="Username">
                    <span className="hidden -visually">
                      Username
                    </span>
                  </label>
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="floating-form w-100">
                  <input
                    className="floating-input form-control"
                    name="password"
                    type="password"
                    placeholder="Password"
                    autoComplete="off"
                    onChange={handleChangeInput('password')}
                    value={form.password}
                    required
                  />
                  <label className="floating-label" data-content="Password">
                    <span className="hidden -visually">
                      Password
                    </span>
                  </label>
                </div>
              </div>
              <button className="btn btn-login btn-secondary btn-pills btn-block" type="submit" disabled={isLoading}>Sign in</button>
            </div>
          </form>

        </div>
      </div>
    </>
  );
}

export default Login;
