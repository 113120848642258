import React from 'react';
import PropTypes from 'prop-types';
import { Provider, useSelector } from 'react-redux';
import { useApiLoading, useApiGet } from 'react-reqq';
import { Router } from 'react-router-dom';
import { render } from 'react-dom';
import { store, history } from 'index';
import _ from 'lodash';
import { AUTHENTICATION } from 'modules/auth/constants';
import * as actions from '../actions';

const alertUniqueID = 'my-toast-marker';

export const AlertMarker = () => <div id={alertUniqueID} />;

const ConfirmModal = ({ opt, onClose }) => {
  const isLoading = useSelector((state) => Object.keys(_.get(state, 'api')).join('|').indexOf('LOADING/') > -1);
  const renderContent = () => {
    if (typeof opt.content === 'function') return opt.content(onClose);
    return opt.content;
  };
  const handleYes = () => {
    opt.onYes(onClose);
  };
  const handleNo = () => {
    opt.onNo(onClose);
  };
  return (
    <>
      <div className="modal" tabIndex="-1" style={{ display: 'block', paddingRight: 15 }}>
        <div className="modal-dialog">
          <div className="modal-content">
            {opt.title && (
              <div className="modal-header">
                {opt.title}
              </div>
            )}
            <div className="modal-body">
              {renderContent()}
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" type="button" onClick={handleYes} disabled={isLoading}>{opt.yesLabel}</button>
              <button className="btn btn-outline-primary" type="button" onClick={handleNo} disabled={isLoading}>{opt.noLabel}</button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};

ConfirmModal.propTypes = {
  opt: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export const confirmAlert = (options) => {
  const rootElement = document.getElementById(alertUniqueID);
  const close = () => {
    render(<AlertMarker />, rootElement);
  };
  const opt = {
    title: 'Confirm',
    content: 'Confirmation Message',
    onYes: (onClose) => onClose(),
    onNo: (onClose) => onClose(),
    yesLabel: 'Yes',
    noLabel: 'No',
    ...options,
  };
  if (!rootElement) {
    alert('Alert Marker not found!'); // eslint-disable-line
    return;
  }

  render(
    <Provider store={store}>
      <Router history={history}>
        <ConfirmModal opt={opt} onClose={close} />
      </Router>
    </Provider>,
    rootElement,
  );
};

const PasswordModal = ({ opt, onClose }) => {
  const profile = useApiGet(`${AUTHENTICATION}.profile`, {});
  const isLoading = useApiLoading('ACCESS_CONTROL_UNLOCK', 'post');
  const [password, setPassword] = React.useState('');
  const handleYes = React.useCallback((e) => {
    e.preventDefault();
    const payload = {
      email: profile.email,
      password,
    };
    actions.unlock(payload, () => {
      opt.onYes(onClose);
      onClose();
    }, () => {
      setPassword('');
    });
  }, [password]);
  const handleNo = React.useCallback(() => {
    opt.onNo(onClose);
  }, []);
  return (
    <>
      <div className="modal" tabIndex="-1" style={{ display: 'block', paddingRight: 15 }}>
        <div className="modal-dialog">
          <form className="modal-content" onSubmit={handleYes}>
            {opt.title && (
              <div className="modal-header justify-content-center">
                {opt.title}
              </div>
            )}
            <div className="modal-body">
              <div>
                <input
                  autoFocus
                  className="form-control"
                  type="password"
                  id="password-input"
                  onChange={({ target }) => setPassword(target.value)}
                  onBlur={() => document.getElementById('password-input').focus()}
                  value={password}
                />
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button className="btn btn-primary" type="submit" disabled={isLoading}>{opt.yesLabel}</button>
              <button className="btn btn-outline-primary" type="button" onClick={handleNo} disabled={isLoading}>{opt.noLabel}</button>
            </div>
          </form>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};

PasswordModal.propTypes = {
  opt: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export const passwordAlert = (options) => {
  const rootElement = document.getElementById(alertUniqueID);
  const close = () => {
    render(<AlertMarker />, rootElement);
  };
  const opt = {
    title: 'Enter Password',
    onYes: (onClose) => onClose(),
    onNo: (onClose) => onClose(),
    yesLabel: 'Submit',
    noLabel: 'Cancel',
    ...options,
  };
  if (!rootElement) {
    alert('Alert Marker not found!'); // eslint-disable-line
    return;
  }

  render(
    <Provider store={store}>
      <Router history={history}>
        <PasswordModal opt={opt} onClose={close} />
      </Router>
    </Provider>,
    rootElement,
  );
};
