/* eslint-disable max-len */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useProfile } from 'modules/auth/hooks';
import Logout from 'modules/auth/containers/Logout';
import PageNotFound from 'modules/common/containers/PageNotFound';
import Sidebar from 'modules/common/components/Sidebar';

import DashboardContainer from 'modules/dashboard/containers/DashboardContainer';
import FileUploadedContainer from 'modules/file-uploaded/containers/FileUploadedContainer';
import CashAidReceiverContainer from 'modules/cash-aid-receiver/containers/CashAidReceiverContainer';
import DisbursementTransactionContainer from 'modules/disbursement-transaction/containers/DisbursementTransactionContainer';
// import CashDisbursementContainer from 'modules/cash-disbursement/containers/CashDisbursementContainer';

import TransactionListContainer from 'modules/transaction-list/containers/TransactionListContainer';

function Private() {
  const profile = useProfile();

  return (
    <div className="internal-page">
      <div className="main-wrapper">
        <Sidebar />
        <div className="flex-grow-1 d-flex flex-row w-100">
          <div className="main-content flex-grow-1">
            {profile.user_type === 'MAKER' && (
              <Switch>
                <Route exact path="/" render={() => <DashboardContainer />} />
                <Route path="/file-uploaded" render={() => <FileUploadedContainer />} />
                <Route path="/cash-aid-receivers" render={() => <CashAidReceiverContainer />} />
                <Route path="/disbursement-transactions" render={() => <DisbursementTransactionContainer />} />
                {/* <Route path="/cash-disbursement" render={() => <CashDisbursementContainer />} /> */}
                <Route path="/settings" render={() => <div>Settings</div>} />
                <Route path="/logout" component={Logout} />
                <Route component={PageNotFound} />
              </Switch>
            )}
            {profile.user_type === 'APPROVER' && (
              <Switch>
                <Route exact path="/" render={() => <DashboardContainer />} />
                <Route path="/transaction-list" render={() => <TransactionListContainer />} />
                <Route path="/settings" render={() => <div>Settings</div>} />
                <Route path="/logout" component={Logout} />
                <Route component={PageNotFound} />
              </Switch>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Private);
