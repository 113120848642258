import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useApiGet, useApiLoading } from 'react-reqq';
import { formatDate, renderStatus } from 'modules/common/helper';
import GenericTable from 'modules/common/components/GenericTable';
import * as actions from '../actions';
import * as c from '../constants';

const disclaimerMsg = 'Disclaimer: The records below were past records that may contain incorrect information; these items were released and may be assigned to other batches';

function TransactionItemViewModal({ onClose, data }) {
  const { list } = useApiGet(c.TRANSACTION_ITEM);
  const isLoading = useApiLoading(c.TRANSACTION_ITEM, 'get');

  const handleOnUpdate = () => {
    const status = data.status === 'Rejected' ? 'inactive' : 'active';
    actions.getTransactionItem(status, data.batch_number);
  };

  const renderClaimedDate = (item) => {
    const value = _.get(item, 'claimed_date');
    if (value === 'Not yet claimed') {
      return value;
    }
    return formatDate(value);
  };

  useEffect(() => {
    handleOnUpdate();
  }, []);
  return (
    <div className="modal-body px-0">
      <div className="row">
        {data.status === 'Rejected' && (
          <div className="col-12 px-5">
            <div className="disclaimer">
              {disclaimerMsg}
            </div>
          </div>
        )}
        <div className="col-12">
          <GenericTable
            isLoading={isLoading}
            format={[
              {
                key: (item) => <p className="text">{_.get(item, 'employee_id')}</p>,
                label: 'EMPLOYEE ID',
              },
              {
                key: (item) => <p className="text">{`${_.get(item, 'first_name')} ${_.get(item, 'middle_name')} ${_.get(item, 'last_name')}`}</p>,
                label: 'NAME',
              },
              {
                key: (item) => <p className="text">{_.get(item, 'cellphone')}</p>,
                label: 'CELLPHONE',
              },
              {
                key: (item) => <p className="text">{renderClaimedDate(item)}</p>,
                label: 'CLAIMED DATE',
              },
              {
                key: (item) => <p className="text">{renderStatus(_.get(item, 'status'))}</p>,
                label: 'STATUS',
              },
              {
                key: (item) => <p className="text">{_.get(item, 'amount')}</p>,
                label: 'AMOUNT',
              },
            ]}
            data={list}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
}
TransactionItemViewModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};

export default TransactionItemViewModal;
