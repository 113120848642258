import React from 'react';
import _ from 'lodash';
import { useAuth } from 'modules/auth/hooks';
import { AlertMarker } from 'modules/common/components/Alert';
import { ModalMarker } from 'modules/common/components/AlertModal';
import Toast from 'modules/common/components/toast';
import Private from './Private';
import Public from './Public';

function App() {
  const auth = useAuth();
  if (_.isEmpty(auth)) return null;
  return (
    <>
      <Toast />
      <ModalMarker />
      <AlertMarker />
      <input id="clipboard-input" />
      {auth.isAuthenticated
        ? <Private />
        : <Public />}
    </>
  );
}

export default React.memo(App);
