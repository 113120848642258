import React from 'react';
import PropTypes from 'prop-types';

function ConfirmAlert({ onClose, onYes, label }) {
  const handleConfirm = () => {
    onYes(onClose);
  };
  return (
    <div className="modal-body">
      <div className="row">
        <div className="col-12 pb-4">
          <div>
            <h3 className="main-title font-weight-bold">Are you sure?</h3>
          </div>
          <div className="my-3">{label}</div>
          <div className="d-flex justify-content-end">
            <button className="btn-blank text-secondary w-25 mr-1" onClick={onClose} type="button">No</button>
            <button className="btn btn-secondary btn-pills w-25" onClick={handleConfirm} type="button">Yes</button>
          </div>
        </div>
      </div>
    </div>
  );
}

ConfirmAlert.defaultProps = {
  label: 'Are you sure?',
};

ConfirmAlert.propTypes = {
  onClose: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default ConfirmAlert;
