import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useApiList, useApiLoading } from 'react-reqq';
import { useProfile } from 'modules/auth/hooks';
import { showModal } from 'modules/common/components/AlertModal';
import FormInput from 'modules/common/form/FormInput';
import FormInputPageSize from 'modules/common/form/FormInputPageSize';
import GenericTable from 'modules/common/components/GenericTable';
import ConfirmAlert from 'modules/common/components/ConfirmAlert';
import * as actions from '../actions';
import * as c from '../constants';

const PAGE_SIZE = 10;
function CreateCashDisbursementModal({ onUpdate }) {
  const [form, setForm] = useState({});
  const [allIsActive, setAllIsActive] = useState(false);
  // prevent looping after setting form
  const [isListed, setIsListed] = useState(true);
  const [list, pager] = useApiList(c.LIST_DISBURSEMENT);
  const isLoading = useApiLoading(c.LIST_DISBURSEMENT, 'list');

  const profile = useProfile();
  const active = (form.checklist || []).filter((item) => item.status === 'active');
  const isAllowed = (active || []).length > 0 && !_.isEmpty(_.get(form, 'payroll_name'));

  const onSetFormData = () => {
    setIsListed(true);
    const newChecklist = list.map((item) => ({ ...item, status: 'active' }));
    setForm({
      ...form,
      checklist: newChecklist,
    });
  };

  const handleUpdateList = () => {
    setIsListed(false);
    actions.listDisbursementBatch({ page: 1, per_page: PAGE_SIZE });
  };

  const handleChecklist = (id) => () => {
    const indexOfTarget = form.checklist.findIndex((item) => item.id === id);
    const statusOfTarget = form.checklist[indexOfTarget].status;
    const newChecklist = form.checklist;
    newChecklist[indexOfTarget] = {
      ...form.checklist[indexOfTarget],
      status: statusOfTarget === 'inactive' ? 'active' : 'inactive',
    };
    setForm({
      ...form,
      checklist: newChecklist,
    });
  };

  const renderCheckbox = (item) => (
    <div className="text-right">
      <button className="btn-checkbox" type="button" onClick={handleChecklist(item.id)}>
        {item.status === 'active'
          ? <i className="fas fa-check-square" />
          : <i className="far fa-square" />}
      </button>
    </div>
  );

  const handleToggleSetAll = () => {
    const value = allIsActive ? 'inactive' : 'active';
    const newChecklist = list.map((item) => ({ ...item, status: value }));
    setForm({
      ...form,
      checklist: newChecklist,
    });
  };

  const renderCheckboxAll = () => (
    <div className="text-right">
      <button className="btn-checkbox" type="button" onClick={handleToggleSetAll}>
        {allIsActive
          ? <i className="fas fa-check-square" />
          : <i className="far fa-square" />}
      </button>
    </div>
  );

  // eslint-disable-next-line no-unused-vars
  const renderAction = (item) => (
    <button
      type="button"
      className="btn-blank text-secondary"
      // onClick={() => console.log('item: ', item)}
    >
      View
    </button>
  );

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (!isAllowed) return;
    const checklist = form.checklist.filter((item) => item.status === 'active');
    const activeList = checklist.map((item) => ({ id: item.id, employee_id: _.get(item, 'attributes.employee_id') }));
    const args = {
      payroll_name: form.payroll_name,
      created_by: profile.email,
      cash_disbursement: activeList,
    };

    showModal({
      title: '',
      withCloseButton: false,
      className: 'modal-small',
      content: (close) => (
        <ConfirmAlert
          onClose={close}
          onYes={() => actions.submitDisbursement(args, () => {
            onUpdate();
            close();
          })}
          label={`Are you sure you want to proceed? You've selected ${activeList.length} cash receivers from the list`}
        />
      ),
    });
  };

  const handleOnFilter = () => {
    setIsListed(false);
    actions.listDisbursementBatch({ page: 1, per_page: form.filter || PAGE_SIZE });
  };

  useEffect(() => {
    handleUpdateList();
  }, []);
  useEffect(() => {
    if (isLoading || isListed) return;
    onSetFormData();
  }, [list]);
  useEffect(() => {
    if ((_.get(form, 'checklist') || []).length === 0) return;

    const activeList = form.checklist.filter((item) => item.status === 'active');
    if (activeList.length === form.checklist.length) {
      setAllIsActive(true);
      return;
    }
    setAllIsActive(false);
  }, [form]);

  return (
    <div className="modal-body px-0">
      <div className="row m-0 px-3 pb-3">
        <div className="col-4">
          <FormInput
            name="payroll_name"
            label="Payroll Name"
            onChange={setForm}
            value={form.payroll_name || ''}
            required
          />
        </div>
        <div className="offset-2 col-6 d-flex justify-content-end align-items-end">
          <FormInputPageSize
            name="filter"
            onChange={setForm}
            value={form.filter || ''}
            required
            onSubmit={handleOnFilter}
            isLoading={isLoading}
            total={_.get(pager, 'pagination.total') || 0}
          />
        </div>
        {/* <div></div> */}
      </div>
      <div className="row">
        <div className="col-12">
          <GenericTable
            isLoading={isLoading}
            format={[
              {
                key: (item) => renderCheckbox(item),
                width: '60px',
                label: renderCheckboxAll(),
              },
              {
                key: (item) => <p className="text">{_.get(item, 'id')}</p>,
                label: '#',
              },
              {
                key: (item) => <p className="text">{_.get(item, 'attributes.subscriber_name')}</p>,
                label: 'SUBSCRIBER NAME',
              },
              {
                key: (item) => <p className="text">{_.get(item, 'attributes.mobile_number')}</p>,
                label: 'MOBILE NO',
              },
              {
                key: (item) => <p className="text">{_.get(item, 'attributes.amount')}</p>,
                label: 'AMOUNT',
              },
              // {
              //   key: (item) => <p className="text">{_.get(item, 'attributes.status_code')}</p>,
              //   label: 'STATUS CODE',
              // },
              // {
              //   key: (item) => renderAction(item),
              //   label: 'ACTION',
              // },
            ]}
            data={form.checklist}
          />
        </div>
        <div className="col-12 text-center">
          <button
            type="button"
            className={`btn ${isAllowed ? 'btn-secondary' : 'btn-disabled'}`}
            onClick={handleOnSubmit}
            disabled={!isAllowed}
          >
            Submit Cash Disbursement
          </button>
        </div>
      </div>
    </div>
  );
}
CreateCashDisbursementModal.defaultProps = {
  onUpdate: () => {},
};

CreateCashDisbursementModal.propTypes = {
  onUpdate: PropTypes.instanceOf(Function),
};

export default CreateCashDisbursementModal;
