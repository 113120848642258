import React from 'react';
import PropTypes from 'prop-types';

function GenericLoadingContent({ isLoading }) {
  return (
    isLoading && (
      <div className="d-flex justify-content-center align-items-center generic-loading">
        <span>
          <span className="spinner-border spinner-border-sm mr-1" />
          Loading...
        </span>
      </div>
    )
  );
}

GenericLoadingContent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default GenericLoadingContent;
