/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const parseNumber = (val) => {
  try {
    return parseFloat(val);
  } catch (err) {
    return NaN;
  }
};
const count = (s1, letter) => s1.split(letter).length - 1;

function FormInputNumber({
  name, onChange, label, value, required, minValue, ...rest
}) {
  // const handleChange = ({ target }) => {
  //   onChange((prev) => ({ ...prev, [name]: target.value }));
  // };
  const handleChange = ({ target }) => {
    const entryValue = target.value;
    const val = parseNumber(entryValue);
    if ((Number.isNaN(val) && !_.isEmpty(val)) || (count(`${entryValue}`, '.') > 1)) return;
    if (entryValue < minValue) {
      onChange((prev) => ({ ...prev, [name]: minValue }));
      return;
    }
    onChange((prev) => ({ ...prev, [name]: entryValue.replace(/[^0-9\.]/g, "") })); // eslint-disable-line
  };
  return (
    <>
      {label && <small>{label} {required && <span className="input-required-text">(Required)</span>}</small>}
      <input
        className="form-control"
        onChange={handleChange}
        value={value || ''}
        {...rest}
      />
    </>
  );
}

FormInputNumber.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  minValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

FormInputNumber.defaultProps = {
  label: '',
  required: false,
  minValue: -10000000,
};

export default FormInputNumber;
