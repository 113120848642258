import React, { useEffect } from 'react';
import _ from 'lodash';
import { useApiList, useApiLoading, useApiGet } from 'react-reqq';
import { showModal } from 'modules/common/components/AlertModal';
import GenericTable from 'modules/common/components/GenericTable';
import DashboardImage1 from 'assets/img/cash-aid-receiver.svg';
import DashboardImage2 from 'assets/img/clipboard.svg';
import DashboardImage3 from 'assets/img/files.svg';
import DashboardShowMoreModal from '../components/DashboardShowMoreModal';
import DashboardCard from '../components/DashboardCard';
import * as actions from '../actions';
import * as c from '../constants';

const PAGE_SIZE = 10;

function DashboardContentApprover() {
  const [list] = useApiList(c.LIST_CASH_AID_RECEIVERS);
  const isLoading = useApiLoading(c.LIST_CASH_AID_RECEIVERS, 'list');
  const dashboardData = useApiGet(c.GET_APPROVER_DASHBOARD);

  const handleOnUpdate = () => {
    actions.listCashAidReceivers({ page: 1, size: PAGE_SIZE });
    actions.getApproverDashboard();
  };

  const handleShowMore = () => {
    showModal({
      title: 'Cash Aid Receivers',
      className: 'modal-large',
      content: (close) => (
        <DashboardShowMoreModal onClose={close} />
      ),
    });
  };


  useEffect(() => {
    handleOnUpdate();
  }, []);

  return (
    <div className="container-card">
      <div className="row mb-4">
        <div className="col-12">
          <h3 className="main-title">Dashboard</h3>
        </div>
      </div>
      <div className="row px-2 mb-4">
        <DashboardCard
          value={_.get(dashboardData, 'pending')}
          label="Total Pending Transactions"
          image={DashboardImage1}
        />
        <DashboardCard
          value={_.get(dashboardData, 'approved')}
          label="Total Approved Transactions"
          image={DashboardImage2}
        />
        <DashboardCard
          value={_.get(dashboardData, 'declined')}
          label="Total Declined Transactions"
          image={DashboardImage3}
        />
      </div>
      <div className="row mb-4">
        <div className="col-12">
          <label className="subtitle">Cash Aid Receivers</label>
        </div>
        <div className="col-12">
          <GenericTable
            isLoading={isLoading}
            format={[
              {
                key: (item) => <p className="text">{_.get(item, 'id')}</p>,
                label: 'ID No.',
              },
              {
                key: (item) => <p className="text">{_.get(item, 'attributes.subscriber_name')}</p>,
                label: 'SUBSCRIBER NAME',
              },
              {
                key: (item) => <p className="text">{_.get(item, 'attributes.mobile_number')}</p>,
                label: 'MOBILE NO',
              },
              {
                key: (item) => <p className="text">{_.get(item, 'attributes.amount')}</p>,
                label: 'AMOUNT',
              },
              {
                key: (item) => <p className="text">{_.get(item, 'attributes.status_code')}</p>,
                label: 'STATUS CODE',
              },
              // {
              //   key: (item) => renderAction(item),
              //   label: 'ACTION',
              // },
            ]}
            data={list}
          />
          {list.length >= 10 && (
            <button
              type="button"
              className="btn-see-more"
              onClick={handleShowMore}
            >
              Show More
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default DashboardContentApprover;
