import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useApiList, useApiLoading } from 'react-reqq';
import Pagination from 'modules/common/components/Pagination';
import GenericTable from 'modules/common/components/GenericTable';
import * as actions from '../actions';
import * as c from '../constants';

const PAGE_SIZE = 10;

function DashboardShowMoreModal({ onClose }) {
  const [list, pager] = useApiList(c.LIST_CASH_AID_RECEIVERS);
  const isLoading = useApiLoading(c.LIST_CASH_AID_RECEIVERS, 'list');

  const handleChangePage = (page) => {
    actions.listCashAidReceivers({ page, size: PAGE_SIZE });
  };

  return (
    <div className="modal-body px-0">
      <div className="row">
        <div className="col-12">
          <GenericTable
            isLoading={isLoading}
            format={[
              {
                key: (item) => <p className="text">{_.get(item, 'id')}</p>,
                label: '#',
              },
              {
                key: (item) => <p className="text">{_.get(item, 'attributes.subscriber_name')}</p>,
                label: 'SUBSCRIBER NAME',
              },
              {
                key: (item) => <p className="text">{_.get(item, 'attributes.mobile_number')}</p>,
                label: 'MOBILE NO',
              },
              {
                key: (item) => <p className="text">{_.get(item, 'attributes.amount')}</p>,
                label: 'AMOUNT',
              },
              {
                key: (item) => <p className="text">{_.get(item, 'attributes.status_code')}</p>,
                label: 'STATUS CODE',
              },
            ]}
            data={list}
          />
          <div className="my-3">
            <Pagination data={pager.pagination} onChange={handleChangePage} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
}
DashboardShowMoreModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
};

export default DashboardShowMoreModal;
