import { useEffect } from 'react';
import { useApiGet } from 'react-reqq';
import _ from 'lodash';
import * as actions from './actions';
import * as c from './constants';

export const useAuth = () => {
  const data = useApiGet(`${c.AUTHENTICATION}`, {});
  useEffect(() => {
    actions.checkAuth();
  }, []);
  return data;
};

export const useProfile = () => {
  const profile = !_.isNil(sessionStorage.profile) ? JSON.parse(sessionStorage.profile) : {};

  return profile;
};
// export const useProfile = () => {
//   const data = useApiGet(`${c.AUTHENTICATION}`, {});
//   const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');

//   return {
//     data,
//     profile,
//   };
// };
