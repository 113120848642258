/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

function FormInputFilterKeyword({
  name, onChange, value, onSubmit, isLoading, ...rest
}) {
  const handleChange = ({ target }) => {
    onChange(target.value);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form className="form-filter-keyword" onSubmit={handleOnSubmit}>
      <small>Filter:</small>
      <div className="input-holder">
        <input
          className="form-control"
          onChange={handleChange}
          value={value || ''}
          {...rest}
        />
      </div>
      <button
        type="submit"
        className={`btn btn-secondary btn-sm ${(isLoading) && 'disabled'}`}
      >
        Filter
        {isLoading && <span className="ml-2 spinner-bar" />}
      </button>
    </form>
  );
}

FormInputFilterKeyword.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,

  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

FormInputFilterKeyword.defaultProps = {
  isLoading: false,
};

export default FormInputFilterKeyword;
