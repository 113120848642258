import { req } from 'react-reqq';
import _ from 'lodash';
import * as c from './constants';

// eslint-disable-next-line import/prefer-default-export
export const listTransactions = (params, callback) => req.get({
  key: c.LIST_TRANSACTION,
  url: '/list-disbursement-batch',
  params,
  transform: (res) => ({
    list: (_.get(res, 'data.data.content') || []).map((item, i) => ({ id: i, ...item })),
    pager: _.get(res, 'meta.pagination') || {},
  }),
  onSuccess: () => {
    if (!_.isNil(callback)) {
      callback();
    }
  },
});
