import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Pager from 'react-pager';

function Pagination({ data, onChange }) {
  const handleOnChange = (page) => {
    onChange(page + 1);
  };

  return (
    (!_.isEmpty(data) && (_.get(data, 'total_pages') || 1) > 1)
      ? (
        <div className="card-paginate">
          <Pager
            total={_.get(data, 'total_pages') || 1}
            current={+(_.get(data, 'current_page') || 1) - 1}
            visiblePages={10}
            titles={{
              first: 'First',
              last: 'Last',
              prev: '‹',
              next: '›',
            }}
            className="pagination pagination-sm align-content-center justify-content-center"
            onPageChanged={handleOnChange}
          />
        </div>
      )
      : ''

  );
}
Pagination.propTypes = {
  data: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
};

Pagination.defaultProps = {
  data: {},
  onChange: () => {},
};

export default Pagination;
