import React, { useEffect } from 'react';
import _ from 'lodash';
import { useApiGet, useApiLoading } from 'react-reqq';
import Helmet from 'react-helmet';
import UploadCSVContainer from 'modules/upload-csv/containers/UploadCSVContainer';
import Pagination from 'modules/common/components/Pagination';
import GenericTable from 'modules/common/components/GenericTable';
import * as actions from '../actions';
import * as c from '../constants';

const PAGE_SIZE = 10;

function FileUploadedContainer() {
  const { list, pager } = useApiGet(c.LIST_FILE);
  const isLoading = useApiLoading(c.LIST_FILE, 'get');

  const handleOnUpdate = () => {
    actions.listFile({ page: 1, size: PAGE_SIZE });
  };
  const handleChangePage = (page) => {
    actions.listFile({ page, size: PAGE_SIZE });
  };

  const handleDownload = (item) => (e) => {
    e.preventDefault();
    const baseURL = process.env.REACT_APP_END_POINT;
    const link = `${baseURL}/download?file-name=${item.file_name}`;
    window.open(link);
  };

  const renderAction = (item) => (
    <button type="submit" className="btn-blank text-secondary" onClick={handleDownload(item)}>
      Download
    </button>
  );

  useEffect(() => {
    handleOnUpdate();
  }, []);

  return (
    <>
      <Helmet>
        <title>File Uploaded - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <div className="container-card">
        <div className="row mb-4">
          <div className="col-12 d-flex">
            <h3 className="main-title">File Uploaded</h3>
            <UploadCSVContainer onUploadCSV={handleOnUpdate} />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <GenericTable
              isLoading={isLoading}
              format={[
                {
                  key: (item) => <p className="text">{_.get(item, 'file_name')}</p>,
                  label: 'FILE NAME',
                },
                {
                  key: (item) => <p className="text">{_.get(item, 'DATE(created_date)')}</p>,
                  label: 'DATE UPLOADED',
                },
                {
                  key: (item) => renderAction(item),
                  label: 'ACTION',
                  width: '100px',
                },
              ]}
              data={list}
            />
            <div className="mt-3">
              <Pagination data={pager} onChange={handleChangePage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

FileUploadedContainer.propTypes = {
};

export default FileUploadedContainer;
