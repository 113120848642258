import React, { useEffect } from 'react';
import _ from 'lodash';
import { useApiGet, useApiLoading } from 'react-reqq';
import { renderStatus, formatDate } from 'modules/common/helper';
import { showModal } from 'modules/common/components/AlertModal';
import Helmet from 'react-helmet';
import Pagination from 'modules/common/components/Pagination';
import UploadCSVContainer from 'modules/upload-csv/containers/UploadCSVContainer';
import GenericTable from 'modules/common/components/GenericTable';
import TransactionItemViewModal from 'modules/transaction-list/modals/TransactionItemViewModal';
import * as actions from '../actions';
import * as c from '../constants';

const PAGE_SIZE = 10;

function DisbursementTransactionContainer() {
  const { list, pager } = useApiGet(c.LIST_TRANSACTION);
  const isLoading = useApiLoading(c.LIST_TRANSACTION, 'get');

  const handleOnUpdate = () => {
    actions.listTransactions({ page: 1, size: PAGE_SIZE });
  };

  const handleChangePage = (page) => {
    actions.listTransactions({ page, size: PAGE_SIZE });
  };

  const handleOpenViewModal = (item) => {
    showModal({
      title: 'Transaction Details',
      className: 'modal-large',
      content: (close) => (
        <TransactionItemViewModal
          onClose={close}
          data={item}
          onUpdate={handleOnUpdate}
        />
      ),
    });
  };

  const renderAction = (item) => (
    <button
      type="button"
      className="btn-blank text-secondary mr-2"
      onClick={() => handleOpenViewModal(item)}
    >
      View
    </button>
  );

  useEffect(() => {
    handleOnUpdate();
  }, []);
  return (
    <>
      <Helmet>
        <title>Disbursement Transaction - {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <div className="container-card">
        <div className="row mb-4">
          <div className="col-12 d-flex">
            <h3 className="main-title">Disbursement Transaction</h3>
            <UploadCSVContainer onCreateDisbursement={handleOnUpdate} />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <GenericTable
              isLoading={isLoading}
              format={[
                {
                  key: (item) => <p className="text">{_.get(item, 'payroll_name')}</p>,
                  label: 'PAYROLL NAME',
                },
                {
                  key: (item) => <p className="text">{_.get(item, 'batch_number')}</p>,
                  label: 'DISBURSEMENT BATCH NUMBER',
                },
                {
                  key: (item) => <p className="text">{renderStatus(_.get(item, 'status'))}</p>,
                  label: 'STATUS',
                },
                {
                  key: (item) => <p className="text">{formatDate(_.get(item, 'created_date'))}</p>,
                  width: '200px',
                  label: 'DATE CREATED',
                },
                {
                  key: (item) => renderAction(item),
                  width: '100px',
                  label: 'ACTION',
                },
              ]}
              data={list}
            />
            <div className="mt-3">
              <Pagination data={pager} onChange={handleChangePage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

DisbursementTransactionContainer.propTypes = {
};

export default DisbursementTransactionContainer;
