import React from 'react';
import PropTypes from 'prop-types';
import Step1 from 'assets/img/step1-download-csv.svg';
import Step2 from 'assets/img/step2-file.svg';
import Step3 from 'assets/img/step3-import-csv.svg';

const list = [
  { id: 1, name: 'Employee ID', required: true },
  { id: 2, name: 'First Name', required: true },
  { id: 3, name: 'Middle Name', required: false },
  { id: 4, name: 'Last Name', required: true },
  { id: 5, name: 'Amount', required: true },
  { id: 6, name: 'Mobile Number', required: true },
  { id: 7, name: 'Employee Address', required: true },
];
function UploadCSVInstructionsModal({ setActiveModal }) {
  const handleView = (e) => {
    e.preventDefault();
    const baseURL = process.env.REACT_APP_END_POINT;
    const link = `${baseURL}/download`;
    window.open(link);
  };
  return (
    <div className="modal-body px-0 csv-instruction-container">
      <div className="row m-0">
        {/* 1ST */}
        <div className="col section-column">
          <div className="image-holder">
            <img alt="" src={Step1} />
          </div>
          <div className="section-title mb-5">
            <label>Download our CSV Template</label>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-secondary-light btn-pills ml-auto mr-2"
              onClick={handleView}
            >
              <div>Download Template</div>
            </button>
          </div>
        </div>

        {/* 2ND */}
        <div className="col mid-column section-column mb-4">
          <div className="image-holder">
            <img alt="" src={Step2} />
          </div>
          <div className="section-title mb-4">
            <label>Enter Information for Each person</label>
          </div>
          <p>Add row for each person in your Company with a column for each Profile Field</p>

          {list.map((item, i) => (
            <div className="d-block" key={item.id}>
              <label>{i + 1}. <span className="font-weight-bold">{item.name}</span> {item.required && '(required)'}</label>
            </div>
          ))}
        </div>

        {/* 3RD */}
        <div className="col section-column">
          <div className="image-holder">
            <img alt="" src={Step3} />
          </div>
          <div className="section-title mb-5">
            <label>Import the Completed file</label>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary-light btn-pills"
              onClick={setActiveModal}
            >
              Import File
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

UploadCSVInstructionsModal.propTypes = {
  setActiveModal: PropTypes.instanceOf(Function).isRequired,
};

export default React.memo(UploadCSVInstructionsModal);
