import React from 'react';
import PropTypes from 'prop-types';
import SuccessImage from 'assets/img/success.svg';

function UploadCSVSuccessfulModal({ onClose, setActiveModal }) {
  return (
    <div className="modal-body">
      <div className="row">
        <div className="col-12 pt-4 pb-5">
          <div className="text-center mb-5">
            <img alt="success" src={SuccessImage} />
          </div>
          <div className="text-center">
            <p className="m-0">Successfully uploaded the <span className="font-weight-bold">CSV File</span>.</p>
            <p className="mb-5">You can check the list under <span className="font-weight-bold">Cash Aid Receivers Tab</span>.</p>
          </div>
          <div className="text-center mb-3">
            <small>Click the import button to import another CSV file.</small>
          </div>
          <div className="text-center mb-4">
            <button type="button" className="btn btn-secondary btn-pills" onClick={setActiveModal}>
              Import File
            </button>
          </div>
          <div className="text-center">
            <button type="button" className="btn btn-secondary-plain btn-pills px-5" onClick={onClose}>
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

UploadCSVSuccessfulModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
  setActiveModal: PropTypes.instanceOf(Function).isRequired,
};

export default React.memo(UploadCSVSuccessfulModal);
