import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import iconLogout from 'assets/img/icon-logout.svg';

function LogoutAlert({ history, onClose }) {
  const handleYes = () => {
    onClose();
    history.push('/logout');
  };
  return (
    <div className="p-4 text-center">
      <img className="mt-3" src={iconLogout} alt="logout" />
      <div className="my-3">Are you sure you want to logout?</div>
      <div className="d-flex justify-content-center">
        <button className="btn btn-link mr-2 px-5 text-decoration-none" onClick={onClose} type="button" autoFocus>No</button>
        <button className="btn btn-primary px-5" onClick={handleYes} type="button">Yes</button>
      </div>
    </div>
  );
}

LogoutAlert.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withRouter(LogoutAlert);
