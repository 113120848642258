import React from 'react';
import PropTypes from 'prop-types';

function DashboardCard({ value, label, image }) {
  return (
    <div className="col px-2">
      <div className="card-dashboard h-100">
        <label className="count">{value}</label>
        <p className="description">
          {label}
        </p>
        <div className="image-holder">
          <img alt="" src={image} />
        </div>
      </div>
    </div>
  );
}

DashboardCard.defaultProps = {
  value: '',
  label: '',
  image: '',
};

DashboardCard.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  image: PropTypes.string,
};

export default DashboardCard;
