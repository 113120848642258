import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from 'modules/auth/containers/Login';
import PageNotFound from 'modules/common/containers/PageNotFound';

function Public() {
  return (
    <Switch>
      <Route path="/signin" component={Login} />
      <Route exact path="/"><Redirect to="/signin" /></Route>
      <Route path="/logout"><Redirect to="/signin" /></Route>
      <Route component={PageNotFound} />
    </Switch>
  );
}

export default Public;
