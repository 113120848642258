import React, { useEffect } from 'react';
import * as actions from '../actions';

function Logout() {
  useEffect(() => {
    actions.logout();
  }, []);
  return (
    <div>
      Logging out...
    </div>
  );
}

export default Logout;
