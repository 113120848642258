import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

function ConfirmAlertRemarks({
  onClose, onYes, label, data, disclaimer,
}) {
  const [remarks, setRemarks] = useState('');
  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (_.isEmpty(data)) {
      onYes(remarks, onClose);
      return;
    }
    onYes(data, remarks, onClose);
  };
  const handleChangeInput = ({ target }) => {
    setRemarks(target.value);
  };

  return (
    <form className="modal-body" onSubmit={handleOnSubmit}>
      <div className="row">
        <div className="col-12 pb-4">
          <div>
            <h3 className="main-title font-weight-bold mb-4">{label}</h3>
          </div>
          <small>Remarks<span className="input-required-text"> (Required)</span></small>

          <div className="mb-2">
            <textarea
              className="form-control"
              onChange={handleChangeInput}
              value={remarks}
              rows="5"
              required
            />
          </div>
          {disclaimer !== '' && (
            <div className="disclaimer">
              Note: {disclaimer}
            </div>
          )}
          <div className="d-flex justify-content-end">
            <button className="btn-blank text-secondary w-25 mr-1" onClick={onClose} type="button">No</button>
            <button className="btn btn-secondary btn-pills w-25" type="submit">Yes</button>
          </div>
        </div>
      </div>
    </form>
  );
}

ConfirmAlertRemarks.defaultProps = {
  label: 'Remarks',
  data: {},
  disclaimer: '',
};

ConfirmAlertRemarks.propTypes = {
  onClose: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
  label: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  disclaimer: PropTypes.string,
};

export default ConfirmAlertRemarks;
