/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import { showModal } from 'modules/common/components/AlertModal';
import UploadCSVInstructionsModal from '../modals/UploadCSVInstructionsModal';
import UploadCSVModal from '../modals/UploadCSVModal';
import CreatePayrollItemModal from '../modals/CreatePayrollItemModal';
import UploadCSVSuccessfulModal from '../modals/UploadCSVSuccessfulModal';
import CreateCashDisbursementModal from '../modals/CreateCashDisbursementModal';

function UploadCSVContainer({ onUploadCSV, onCreateDisbursement }) {
  const handleOpenCSVInstructionModal = () => {
    showModal({
      title: 'Upload CSV File',
      className: 'modal-large',
      content: (close) => (
        <UploadCSVInstructionsModal
          onClose={close}
          setActiveModal={handleOpenUploadModal}
        />
      ),
    });
  };

  const handleOpenUploadModal = () => {
    showModal({
      title: '',
      className: 'modal-small',
      content: (close) => (
        <UploadCSVModal
          onClose={close}
          setActiveModal={handleSuccessfulUpload}
          onUpdate={onUploadCSV}
        />
      ),
    });
  };

  const handleSuccessfulUpload = () => {
    showModal({
      title: '',
      className: 'modal-small',
      content: (close) => (
        <UploadCSVSuccessfulModal
          onClose={close}
          setActiveModal={handleOpenCSVInstructionModal}
        />
      ),
    });
  };

  const handleOpenCreateDisbursementModal = () => {
    showModal({
      title: 'Create Cash Disbursement',
      className: 'modal-large',
      content: (close) => (
        <CreateCashDisbursementModal onUpdate={onCreateDisbursement} onClose={close} />
      ),
    });
  };

  const handleOpenCreatePayrollItemModal = () => {
    showModal({
      title: 'Create Payroll Item',
      className: 'modal-medium',
      content: (close) => (
        <CreatePayrollItemModal onClose={close} />
      ),
    });
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-secondary-light btn-pills ml-auto mr-2"
        onClick={handleOpenCreatePayrollItemModal}
      >
        Create Payroll Item
      </button>
      <button
        type="button"
        className="btn btn-secondary-light btn-pills mr-2"
        onClick={handleOpenCSVInstructionModal}
      >
        Upload CSV File
      </button>
      <button
        type="button"
        className="btn btn-primary-light btn-pills"
        onClick={handleOpenCreateDisbursementModal}
      >
        Create Disbursement
      </button>
    </>
  );
}

UploadCSVContainer.defaultProps = {
  onUploadCSV: () => {},
  onCreateDisbursement: () => {},
};

UploadCSVContainer.propTypes = {
  onUploadCSV: PropTypes.instanceOf(Function),
  onCreateDisbursement: PropTypes.instanceOf(Function),
};

export default UploadCSVContainer;
