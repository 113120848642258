import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useApiLoading } from 'react-reqq';
import _ from 'lodash';
import { useProfile } from 'modules/auth/hooks';
import FormUploadFile from 'modules/common/form/FormUploadFile';
import * as actions from '../actions';
import * as c from '../constants';

function UploadCSVModal({ setActiveModal, onUpdate }) {
  const [form, setForm] = useState({});
  const isLoading = useApiLoading(c.UPLOAD_CSV, 'post');
  const profile = useProfile();

  const fileIsEmpty = _.isEmpty(_.get(form, 'resource') || '');

  const handleSubmit = () => {
    const args = {
      ...form,
      username: profile.email,
    };
    actions.uploadCSV(args, () => {
      setActiveModal();
      onUpdate();
    });
  };

  const handleOnChangeUpload = (item) => {
    const link = _.get(item, '0.response.data.resource');
    setForm({
      ...form,
      resource: link,
    });
  };

  return (
    <div className="modal-body mt-0">
      <div className="row d-flex justify-content-center">
        <div className="col-12 text-center mb-4">
          <h3 className="main-title">Uploading CSV File</h3>
        </div>

        <div className="col-8 mb-4">
          <FormUploadFile
            fileType="text/csv"
            isImageOnly={false}
            disabled={false}
            onChange={handleOnChangeUpload}
            caption="Choose a CSV file to upload."
            // disabled={!(_.isNil(status) || status === 'pending')}
          />
        </div>

        <div className="col-12 text-center">
          <button
            type="button"
            className={`btn btn-pills ${fileIsEmpty ? 'btn-disabled' : 'btn-secondary'}`}
            onClick={handleSubmit}
            disabled={fileIsEmpty || isLoading}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
}

UploadCSVModal.propTypes = {
  setActiveModal: PropTypes.instanceOf(Function).isRequired,
  onUpdate: PropTypes.instanceOf(Function).isRequired,
};

export default React.memo(UploadCSVModal);
