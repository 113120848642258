import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useApiLoading } from 'react-reqq';
import { useProfile } from 'modules/auth/hooks';
import SubmitButtonLoading from 'modules/common/components/SubmitButtonLoading';
import FormInput from 'modules/common/form/FormInput';
import * as actions from '../actions';
import * as c from '../constants';

const initForm = {
  notif_mode: '',
  disbursement_mode: '',
};
function TransactionItemApproveModal({ onClose, data, onUpdate }) {
  const [form, setForm] = useState(initForm);
  const isLoading = useApiLoading(c.APPROVE_TRANSACTION, 'post');
  const profile = useProfile();

  const handleOnChange = (key) => {
    switch (key) {
      case 'opt_notif_1':
        setForm({
          ...form,
          notif_mode: 0,
        });
        break;
      case 'opt_notif_2':
        setForm({
          ...form,
          notif_mode: 1,
        });
        break;
      case 'opt_dis_1':
        setForm({
          ...form,
          disbursement_mode: 2,
        });
        break;
      case 'opt_dis_2':
        setForm({
          ...form,
          disbursement_mode: 3,
        });
        break;
      default:
        break;
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    const params = {
      email: profile.email,
      batch_no: data.batch_number,
      notif_mode: form.notif_mode,
      disbursement_mode: form.disbursement_mode,
    };
    const payload = {
      email: form.email,
      password: form.password,
    };
    if (isLoading) return;
    actions.approveTransaction(params, payload, () => {
      onUpdate();
      onClose();
    });
  };

  const isAllowed = form.notif_mode !== ''
    && form.disbursement_mode !== ''
    && !_.isEmpty(form.password)
    && !isLoading;

  return (
    <form className="modal-body" onSubmit={handleOnSubmit}>
      <div className="row mb-4">
        <div className="col-6">
          <div className="row">
            <div className="col-12">
              <label className="d-block font-weight-bold">
                Approver Confirmation:
              </label>
            </div>
            <div className="col-12 mb-3">
              <FormInput
                name="email"
                label="Email"
                onChange={setForm}
                value={form.email || ''}
                required
              />
            </div>
            <div className="col-12">
              <FormInput
                name="password"
                label="Password"
                onChange={setForm}
                type="password"
                value={form.password || ''}
                required
              />
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="col-12">
            <label className="d-block font-weight-bold">Notification Mode:</label>
          </div>
          <div className="col-12 mb-3">
            <div className="d-block">
              <button type="button" className="btn-radio-pair mb-2" onClick={() => handleOnChange('opt_notif_1')}>
                {form.notif_mode === 0
                  ? <i className="fas fa-check-circle" />
                  : <i className="far fa-circle" />}
                No notification
              </button>
            </div>
            <div className="d-block">
              <button type="button" className="btn-radio-pair" onClick={() => handleOnChange('opt_notif_2')}>
                {form.notif_mode === 1
                  ? <i className="fas fa-check-circle" />
                  : <i className="far fa-circle" />}
                Notify via SMS
              </button>
            </div>
          </div>

          <div className="col-12">
            <label className="d-block font-weight-bold">Disbursement Mode:</label>
          </div>
          <div className="col-12 mb-3">
            <div className="d-block">
              <button type="button" className="btn-radio-pair mb-2" onClick={() => handleOnChange('opt_dis_1')}>
                {form.disbursement_mode === 2
                  ? <i className="fas fa-check-circle" />
                  : <i className="far fa-circle" />}
                Pera Padala
              </button>
            </div>
            {/* <div className="d-block">
              <button type="button" className="btn-r
              adio-pair" onClick={() => handleOnChange('opt_dis_2')}>
                {form.disbursement_mode === 3
                  ? <i className="fas fa-check-circle" />
                  : <i className="far fa-circle" />}
                Remit to Account
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <SubmitButtonLoading isLoading={isLoading} disabled={!isAllowed} />
        </div>
      </div>
    </form>
  );
}

TransactionItemApproveModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  onUpdate: PropTypes.instanceOf(Function).isRequired,
};

export default TransactionItemApproveModal;
