import { req } from 'react-reqq';
import _ from 'lodash';
import * as c from './constants';

// eslint-disable-next-line import/prefer-default-export
export const listCashAidReceivers = (params, callback) => req.list({
  key: c.LIST_CASH_AID_RECEIVERS,
  url: '/disbursement-batch/payroll-item',
  // ?per_page=10&page=1
  params,
  onSuccess: () => {
    if (!_.isNil(callback)) {
      callback();
    }
  },
});

export const getMakerDashboard = (params, callback) => req.get({
  key: c.GET_MAKER_DASHBOARD,
  url: '/maker-counter',
  params,
  transform: (res) => _.get(res, 'data') || {},
  onSuccess: () => {
    if (!_.isNil(callback)) {
      callback();
    }
  },
});

export const getApproverDashboard = (params, callback) => req.get({
  key: c.GET_APPROVER_DASHBOARD,
  url: '/approver-counter',
  params,
  transform: (res) => _.get(res, 'data') || {},
  onSuccess: () => {
    if (!_.isNil(callback)) {
      callback();
    }
  },
});
