/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const getCell = (item, col) => {
  try {
    return typeof col.key === 'function' ? col.key(item) : _.get(item, col.key);
  } catch (err) {
    return '-';
  }
};

const getStyle = (col) => _.assign({}, col.style || {}, col.width ? { width: col.width } : {});

function GenericTable({
  data,
  format,
  selected,
  onSelectRow,
  minHeight,
  height,
  selectedKey,
  flashKey,
  className,
  showHeader,
  noRowsLabel,
  footer,
  isLoading,
}) {
  const handleSelectRow = (item) => (e) => {
    e.preventDefault();
    if (onSelectRow) {
      onSelectRow(item);
    }
  };

  return (
    <div
      className="generic-list"
      style={{
        minHeight,
        height,
        overflowY: 'auto',
        position: 'relative',
      }}
    >
      {isLoading && (
        // <div
        //   className="text-center mt-5"
        //   style={{
        //     position: 'absolute',
        //     zIndex: 5,
        //     left: 0,
        //     right: 0,
        //     margin: '100px auto',
        //     width: 40,
        //     height: 40,
        //     background: '#eaeaea',
        //     borderRadius: '50%',
        //     paddingTop: 6,
        //     fontWeight: 'bolder',
        //     fontSize: 20,
        //   }}
        // >
        //   <span className="fas fa-1x fa-spin fa-spinner" />
        // </div>
        <div className="d-flex justify-content-center align-items-center generic-loading mt-5">
          <span>
            <span className="spinner-border spinner-border-sm mr-1" />
            Loading...
          </span>
        </div>
      )}
      <table className={`table table-link table-light generic-table mb-0 ${className}`}>
        {showHeader && (
          <thead className="table-header">
            <tr>
              {format.map((col) => (
                <th
                  key={col.label}
                  className={`text-ellipsis col-header ${col.className
                    || ''}`}
                  style={{
                    borderTop: 0,
                  }}
                >
                  {col.label}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className="table-body">
          {data.length < 1 ? (
            <tr className="text-center">
              <td className="col-item" colSpan={format.length}>
                <small className="text-muted">{noRowsLabel}</small>
              </td>
            </tr>
          ) : (
            data.map((item) => {
              const isSelected = `${selected}` === `${_.get(item, selectedKey)}`;
              const key = item[selectedKey];

              const getClassName = () => {
                const flash = `${key}` === `${flashKey}` ? 'active' : '';
                return isSelected ? `bg-dark text-white font-weight-bold${flash}` : flash;
              };

              return (
                <tr
                  key={key}
                  className={getClassName()}
                  onClick={handleSelectRow(item)}
                  // style={{ cursor: 'pointer' }}
                >
                  {format.map((col) => (
                    <td
                      key={`${key}-${col.label}`}
                      className={`col-item ${col.className || ''}`}
                      style={getStyle(col)}
                    >
                      {getCell(item, col)}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
        {footer && <tfoot>{footer(format, data)}</tfoot>}
      </table>
    </div>
  );
}

GenericTable.defaultProps = {
  data: [],
  format: [],
  onSelectRow: false,
  selected: '',
  minHeight: '300px',
  height: '',
  selectedKey: 'id',
  className: '',
  showHeader: true,
  noRowsLabel: 'There are no records found.',
  footer: false,
  flashKey: '',
  isLoading: false,
};

GenericTable.propTypes = {
  data: PropTypes.instanceOf(Array),
  format: PropTypes.instanceOf(Array),
  onSelectRow: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.func,
    PropTypes.bool,
  ]),
  selected: PropTypes.string,
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedKey: PropTypes.string,
  className: PropTypes.string,
  showHeader: PropTypes.bool,
  noRowsLabel: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.func,
    PropTypes.string,
  ]),
  footer: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  flashKey: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default GenericTable;


// import React from 'react';
// import PropTypes from 'prop-types';
// import _ from 'lodash';
// import { AutoSizer, Table, Column } from 'react-virtualized';

// function GenericTable({
//   // className, selectedKey,
//   data, format, isLoading,
// }) {
//   return (
//     <div className="h-100 w-100 pr-2">
//       <AutoSizer>
//         {({ height, width }) => (
//           <Table
//             width={width}
//             height={height}
//             headerHeight={33}
//             rowHeight={33}
//             rowCount={data.length}
//             noRowsRenderer={() => (
//               <div className="text-center">
//                 <small>{isLoading ? <span><span className="spinner-bar" /></span> : 'There are no items.'}</small>
//               </div>
//             )}
//             rowGetter={({ index }) => data[index]}
//           >
//             {format.map((c) => (
//               <Column
//                 key={c.label}
//                 label={c.label}
//                 dataKey={c.key}
//                 width={c.width || 100}
//                 flexGrow={!c.width ? 1 : 0}
//                 className={c.className || ''}
//                 headerRenderer={({ label }) => <small className={c.className || ''} style={{ display: 'block' }} title={label}>{label}</small>}
//                 cellRenderer={({ rowData, rowIndex }) => (
//                   typeof c.key === 'function' ? c.key(rowData, rowIndex) : <small title={_.get(rowData, c.key)}>{_.get(rowData, c.key) || '-'}</small>
//                 )}
//               />
//             ))}
//           </Table>
//         )}
//       </AutoSizer>
//     </div>
//   );
//   // return (
//   //   <table className={className}>
//   //     <thead>
//   //       <tr>
//   //         {format.map((c) => (
//   //           <th key={c.label} className={c.className || ''} style={{ width: c.width || 'initial' }}>
//   //             <small>{c.label}</small>
//   //           </th>
//   //         ))}
//   //       </tr>
//   //     </thead>
//   //     <tbody>
//   //       {data.length < 1
//   //         ? (
//   //           <tr className="text-center">
//   //             <td colSpan={format.length}>There are no items.</td>
//   //           </tr>
//   //         ) : data.map((row, i) => (
//   //           <tr key={row[selectedKey]}>
//   //             {format.map((c) => (
//   //               <td
//   //                 key={`${row[selectedKey]}-${c.label}`}
//   //                 className={c.className || ''}
//   //                 style={{ width: c.width || 'initial' }}
//   //               >
//   //                 {typeof c.key === 'function' ? c.key(row, i) : <small className="text-truncate" title={_.get(row, c.key) || '-'}>{_.get(row, c.key) || '-'}</small>}
//   //               </td>
//   //             ))}
//   //           </tr>
//   //         ))}
//   //     </tbody>
//   //   </table>
//   // );
// }

// GenericTable.propTypes = {
//   // className: PropTypes.string,
//   // selectedKey: PropTypes.string,
//   data: PropTypes.instanceOf(Array).isRequired,
//   format: PropTypes.instanceOf(Array).isRequired,
//   isLoading: PropTypes.bool,
// };

// GenericTable.defaultProps = {
//   // className: 'table table-sm table-striped table-fixed-header',
//   // selectedKey: 'id',
//   isLoading: false,
// };

// export default GenericTable;
