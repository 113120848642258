import { req } from 'react-reqq';
import _ from 'lodash';
import { ToastSuccess } from 'modules/common/components/toast';
import * as c from './constants';

export const uploadCSV = (payload, callback) => req.post({
  key: c.UPLOAD_CSV,
  url: '/submit',
  payload,
  onSuccess: () => {
    ToastSuccess('Successfully Uploaded!');
    if (!_.isNil(callback)) {
      callback();
    }
  },
});

export const listDisbursementBatch = (params, callback) => req.list({
  key: c.LIST_DISBURSEMENT,
  url: '/disbursement-batch',
  params,
  onSuccess: () => {
    if (!_.isNil(callback)) {
      callback();
    }
  },
});

export const submitDisbursement = (payload, callback) => req.post({
  key: c.LIST_DISBURSEMENT,
  url: '/disbursement-batch',
  payload,
  onSuccess: () => {
    ToastSuccess('Successfully Uploaded!');
    if (!_.isNil(callback)) {
      callback();
    }
  },
});

export const submitPayrollItem = (payload, callback) => req.post({
  key: c.PAYROLL,
  url: '/submit-payroll-item',
  payload,
  onSuccess: () => {
    ToastSuccess('Successfully Submitted!');
    if (!_.isNil(callback)) {
      callback();
    }
  },
});
