import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useApiGet, useApiLoading } from 'react-reqq';
import GenericLoadingContent from 'modules/common/components/GenericLoadingContent';
import SubmitButtonLoading from 'modules/common/components/SubmitButtonLoading';
import FormInput from 'modules/common/form/FormInput';
import FormMobileNumber from 'modules/common/form/FormMobileNumber';
import FormInputNumber from 'modules/common/form/FormInputNumber';
import * as actions from '../actions';
import * as c from '../constants';

function CashAidReceiverUpdateItemModal({ onClose, onUpdate, data }) {
  const [form, setForm] = useState({});
  const { payrollData } = useApiGet(c.PAYROLL_ITEM);
  const isLoading = useApiLoading(c.PAYROLL_ITEM, 'get');
  const isLoadingPost = useApiLoading(c.PAYROLL_ITEM, 'post');

  const onSetFormData = () => {
    setForm({
      employee_id: _.get(payrollData, 'employee_id') || '',
      first_name: _.get(payrollData, 'first_name') || '',
      middle_name: _.get(payrollData, 'middle_name') || '',
      last_name: _.get(payrollData, 'last_name') || '',
      amount: _.get(payrollData, 'amount') || 0,
      cellphone: _.get(payrollData, 'cellphone') || '',
      address: _.get(payrollData, 'address') || '',
      account_number: _.get(payrollData, 'account_number') || '',
      bank_code: _.get(payrollData, 'bank_code') || '',
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (isLoadingPost) return;
    actions.updatePayrollItem(data.id, form, () => {
      onUpdate();
      onClose();
    });
  };

  useEffect(() => {
    if (isLoading) {
      setForm({});
    }
  }, [isLoading]);

  useEffect(() => {
    if (_.isNil(data.id)) return;
    actions.viewPayrollItem(data.id);
  }, [data]);

  useEffect(() => {
    if (_.isNil(payrollData)) return;
    onSetFormData();
  }, [payrollData]);

  return (
    <form className="modal-body" onSubmit={handleOnSubmit}>
      <GenericLoadingContent isLoading={isLoading} />
      <div className="row">
        <div className="col-12">
          <label className="font-weight-bold">Personal Information</label>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <FormInput
            name="first_name"
            label="First Name"
            onChange={setForm}
            value={form.first_name || ''}
            required
          />
        </div>
        <div className="col">
          <FormInput
            name="middle_name"
            label="Middle Name"
            onChange={setForm}
            value={form.middle_name || ''}
          />
        </div>
        <div className="col">
          <FormInput
            name="last_name"
            label="Last Name"
            onChange={setForm}
            value={form.last_name || ''}
            required
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-4">
          <FormMobileNumber
            name="cellphone"
            label="Mobile Number"
            onChange={setForm}
            value={form.cellphone || ''}
            required
          />
        </div>
        <div className="col">
          <FormInput
            name="address"
            label="Address"
            onChange={setForm}
            value={form.address || ''}
            required
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <label className="font-weight-bold">Transaction Information</label>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          <FormInput
            name="employee_id"
            label="Employee ID"
            onChange={setForm}
            value={form.employee_id || ''}
            required
          />
        </div>
        <div className="col">
          <FormInputNumber
            name="amount"
            label="Amount"
            type="number"
            onChange={setForm}
            value={form.amount || ''}
            minValue={0}
            required
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <FormInput
            name="account_number"
            label="Account Number"
            onChange={setForm}
            value={form.account_number || ''}
            required
          />
        </div>
        <div className="col">
          <FormInput
            name="bank_code"
            label="Bank Code"
            onChange={setForm}
            value={form.bank_code || ''}
            required
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <SubmitButtonLoading isLoading={isLoadingPost} disabled={isLoadingPost} />
      </div>
    </form>
  );
}

CashAidReceiverUpdateItemModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
  onUpdate: PropTypes.instanceOf(Function).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};

export default CashAidReceiverUpdateItemModal;
