import React from 'react';
import PropTypes from 'prop-types';

const ToastTemplate = ({ icon, content }) => (
  <div className="d-flex align-items-baseline">
    {icon && <i className={`fa fa-${icon} mr-3`} />}
    <div className="flex-grow-1 mr-2">{content}</div>
  </div>
);

ToastTemplate.propTypes = {
  icon: PropTypes.string,
  content: PropTypes.string.isRequired,
};

ToastTemplate.defaultProps = {
  icon: '',
};

export default ToastTemplate;
