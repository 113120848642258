import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { configureApi } from 'react-reqq';
import _ from 'lodash';
import App from 'App';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-virtualized/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import 'assets/sb-admin-2/scss/sb-admin-2.scss';
// import 'assets/bootstrap/scss/bootstrap.scss';
import 'assets/fontawesome/css/all.min.css';
import 'assets/user-style.scss';

import 'assets/styles/styles.scss';

import { ToastError } from 'modules/common/components/toast';

const DEFAULT_HEADERS = { 'Content-Type': 'application/json' };
export const history = createBrowserHistory();

export const store = configureApi({
  endpoint: process.env.REACT_APP_END_POINT,
  requestHeaders: () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      return {
        ...DEFAULT_HEADERS,
      };
    }
    return {
      ...DEFAULT_HEADERS,
      Authorization: `${token}`,
    };
  },
  timeout: 180000,
  onError: (err) => {
    const err_message = _.get(err, 'response.message');
    if (err.status === 422) {
      const err_raw = _.get(err, 'response.errors') || {};
      const err_keys = Object.keys(err_raw);
      const err_messages = err_keys
        .map((k) => {
          const er = _.get(err_raw, `${k}`);
          return Array.isArray(er) ? er[0] : er;
        })
        .filter((m, i) => i < 3);
      ToastError(err_messages.length > 0 ? err_messages.join('\n') : 'Unable to process');
      return;
    }
    if (err.status === 403 && (err_message === 'Token Expired' || err_message === 'Token expired.' || err_message === 'Invalid token.' || err_message === 'Invalid Token')) {
      ToastError(err_message);
      history.push('/logout');
      return;
    }

    if (err.status === 401) {
      ToastError(err_message);
      history.push('/logout');
      return;
    }
    if (err.status === 504) {
      ToastError('Request timed out!');
      return;
    }
    const msg = _.get(err, 'response.message');
    if (typeof msg === 'string') ToastError(msg);
  },
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
);
