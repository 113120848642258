import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { render } from 'react-dom';
import { store, history } from 'index';

const modalUniqueID = 'my-modal-marker';

export const showModal = (options = {}) => {
  const opt = {
    title: 'Modal',
    withCloseButton: true,
    content: 'Modal Message',
    headerClassName: '',
    withHeader: true,
    className: '',
    backdrop: false,
    blinkTitle: false,
    ...options,
  };
  const rootElement = document.getElementById(modalUniqueID);
  if (!rootElement) {
    alert('Alert Marker not found!'); // eslint-disable-line
    return;
  }

  document.body.className = 'modal-open';
  const prevElem = document.activeElement;
  const close = () => {
    document.body.className = '';
    render(<div id={modalUniqueID} />, rootElement);
    setTimeout(() => {
      try {
        prevElem.focus();
      } catch (err) {} // eslint-disable-line
    }, 100);
  };

  const renderContent = () => {
    if (typeof opt.content === 'function') return opt.content(close);
    return opt.content;
  };

  render(
    <Provider store={store}>
      <Router history={history}>
        <div className={`modal ${opt.className}`} tabIndex="-1" role="dialog" style={{ display: 'block', paddingRight: 15 }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {(opt.withHeader && typeof opt.title === 'string') && (
                <div className={`modal-header ${opt.headerClassName}`}>
                  <h5 className="modal-title">{opt.title}</h5>
                  {opt.withCloseButton && (
                    <button type="button" className="btn-close" onClick={close}>
                      <i className="fas fa-times" />
                    </button>
                  )}
                </div>
              )}
              {typeof opt.title === 'function' && (
                <div className={`modal-header ${opt.headerClassName}`}>
                  {opt.title()}
                </div>
              )}
              {renderContent()}
            </div>
          </div>
        </div>
        <div className={`modal-backdrop fade show${!opt.backdrop ? ' bg-hidden' : ''}`} />
      </Router>
    </Provider>,
    rootElement,
  );
};

export const ModalMarker = () => <div id={modalUniqueID} />;
