import React from 'react';


export const IconArrowExpand = () => (
  <svg className="icon arrow-expand" viewBox="0 0 24 24">
    <path
      d="M14.799 2.297a9.566 9.566 0 01-2.364 18.834 9.515 9.515 0 01-8.903-6.066.652.652 0 111.215-.476 8.261 8.261 0 100-6.046.652.652 0 11-1.215-.476A9.566 9.566 0 0114.8 2.297zM12.24 7.626l3.445 3.444a.65.65 0 010 .991l-3.445 3.444a.652.652 0 01-.918-.928l2.359-2.36-11.029.001a.652.652 0 110-1.305H13.69l-2.373-2.364a.653.653 0 11.923-.923z"
    />
  </svg>
);
export const IconArrowExpandRotate = () => (
  <svg className="icon arrow-expand rotate" viewBox="0 0 24 24">
    <path
      d="M14.799 2.297a9.566 9.566 0 01-2.364 18.834 9.515 9.515 0 01-8.903-6.066.652.652 0 111.215-.476 8.261 8.261 0 100-6.046.652.652 0 11-1.215-.476A9.566 9.566 0 0114.8 2.297zM12.24 7.626l3.445 3.444a.65.65 0 010 .991l-3.445 3.444a.652.652 0 01-.918-.928l2.359-2.36-11.029.001a.652.652 0 110-1.305H13.69l-2.373-2.364a.653.653 0 11.923-.923z"
    />
  </svg>
);

export const IconChat = () => (
  <svg className="icon legend" viewBox="0 0 24 24">
    <path
      d="M17.296 7.241c-.049.505-.33.959-.763 1.23-.123.101-.349.286-.349.355a.48.48 0 01-.142.345.49.49 0 01-.349.142.492.492 0 01-.349-.137.481.481 0 01-.147-.342c.045-.458.304-.87.702-1.11.246-.206.41-.35.41-.483a.489.489 0 00-.491-.457c-.26 0-.475.2-.49.457 0 .271-.223.49-.497.49a.493.493 0 01-.496-.49 1.475 1.475 0 011.48-1.395c.791 0 1.443.614 1.48 1.395zM15.7 9.761a.489.489 0 00-.346.146.478.478 0 00-.138.345.476.476 0 00.138.346.494.494 0 00.843-.348.494.494 0 00-.497-.492v.004zm-9.988 3.422a.493.493 0 00-.496.49c0 .272.222.491.496.491h.041c.274 0 .496-.22.496-.49a.493.493 0 00-.496-.491h-.04zm1.724 0a.493.493 0 00-.497.49c0 .272.223.491.497.491h.04c.275 0 .497-.22.497-.49a.493.493 0 00-.496-.491h-.041zM22 5.353v5.518a2.34 2.34 0 01-.692 1.673 2.393 2.393 0 01-1.687.694h-6.977c-.04 0-.143.12-.241.209l-.131.117-1.82 1.64a.514.514 0 01-.542.088.504.504 0 01-.308-.449v-5.2H4.408a1.413 1.413 0 00-1.417 1.392v5.519c.004.367.156.718.421.974.266.257.625.398.996.393h7.184a.5.5 0 01.331.14l1.482 1.344v-4.653c0-.27.223-.49.497-.49s.496.22.496.49v5.764a.484.484 0 01-.49.484.513.513 0 01-.34-.133L11.4 18.903H4.408a2.406 2.406 0 01-1.696-.681A2.351 2.351 0 012 16.554v-5.519c.007-1.31 1.082-2.371 2.408-2.374h5.196V5.353C9.618 4.045 10.697 2.993 12.02 3h7.599c.631 0 1.237.247 1.683.688A2.34 2.34 0 0122 5.353zm-.993 0a1.358 1.358 0 00-.403-.975 1.39 1.39 0 00-.985-.402h-7.592a1.405 1.405 0 00-1.426 1.377v8.376l.998-.89c.046-.043.098-.08.136-.115a1.26 1.26 0 011.166-.469h6.725c.37 0 .725-.147.986-.407.26-.26.405-.613.402-.979V5.353h-.007z"
    />
  </svg>
);

export const IconLike = () => (
  <svg className="icon legend" viewBox="0 0 24 24">
    <path
      d="M21.4 15.6A2.443 2.443 0 0022 13.932a2.51 2.51 0 00-.559-1.44c.41-.881.189-1.92-.547-2.571a4.856 4.856 0 00-3.455-.804c-.712.034-1.42.122-2.117.264-.215.038-.443.083-.676.133a7.125 7.125 0 01.539-2.43c.63-1.29.59-2.795-.11-4.051A2.773 2.773 0 0012.877 2a1.094 1.094 0 00-.83.369 2.748 2.748 0 00-.361 2c-.568 1.48-2.16 5.113-3.507 6.12a.458.458 0 00-.068.058 4.494 4.494 0 00-.844 1.23 1.817 1.817 0 00-.857-.208H3.79c-.986 0-1.785.775-1.79 1.733V20.1c.003.96.803 1.736 1.79 1.737h2.625c.369 0 .728-.111 1.029-.318l1.011.117c1.903.225 3.82.326 5.736.305.514.038.994.058 1.442.058a9.92 9.92 0 002-.175 3.756 3.756 0 002.672-1.615 2.5 2.5 0 00.293-1.602 2.635 2.635 0 00.803-3.008zM3.79 20.711a.62.62 0 01-.628-.61v-6.803a.62.62 0 01.628-.61h2.625a.62.62 0 01.628.61v6.798a.62.62 0 01-.628.61H3.79v.005zm16.51-5.6a.55.55 0 00-.077.68c.115.218.182.456.197.7a1.656 1.656 0 01-.81 1.446.56.56 0 00-.197.644c.104.36.062.746-.116 1.078a2.71 2.71 0 01-1.902 1.062 11.655 11.655 0 01-3.136.092h-.06a43.669 43.669 0 01-5.593-.297l-.433-.05c.026-.121.04-.245.039-.368v-6.8c0-.176-.029-.35-.082-.518a3.472 3.472 0 01.8-1.436c1.933-1.488 3.82-6.513 3.903-6.73a.516.516 0 00.012-.281c-.08-.403-.06-.819.056-1.214a1.56 1.56 0 011.214.566c.451.97.432 2.085-.051 3.04-.724 2.129-.784 3.25-.211 3.744a.959.959 0 00.938.167c.262-.058.514-.108.75-.147l.056-.013c1.32-.28 3.688-.452 4.51.277.418.409.48 1.05.145 1.527a.55.55 0 00.103.728c.272.26.441.606.478.975-.01.431-.204.839-.535 1.125l.002.002z"
    />
  </svg>
);

export const IconCardIssurance = () => (
  <svg className="icon-menu" viewBox="0 0 24 24">
    <path
      d="M18 4h-2V2h-2v2h-4V2H8v2H6a2 2 0 00-2 2v14a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2zM8 15.989A4.02 4.02 0 0111.889 12a2 2 0 11.222 0A4.02 4.02 0 0116 15.989H8z"
    />
  </svg>
);

export const IconContentManagement = () => (
  <svg className="icon-menu" viewBox="0 0 24 24">
    <path
      d="M22 3v18H2V3h20zm-2 6H4v10h16V9zm-2 6v2h-5v-2h5zm-7-4v6H6v-6h5zm7 0v2h-5v-2h5z"
    />
  </svg>
);

export const IconCustomerRelation = () => (
  <svg className="icon-menu" viewBox="0 0 24 24">
    <path
      d="M18.452 17.041l-3.192-.797-.272-1.086a5.74 5.74 0 001.612-3.196c.484-.136.84-.549.903-1.047l.208-1.666a1.247 1.247 0 00-.798-1.321l.077-1.568.312-.312a1.92 1.92 0 00.046-2.595 3.502 3.502 0 00-3.157-1.439 5.495 5.495 0 00-3.158.834C7.483 2.923 7 4.568 7 6.178c0 .374.091 1.221.151 1.723a1.25 1.25 0 00-.863 1.347l.208 1.666c.068.534.47.966 1 1.071a5.772 5.772 0 001.532 3.1l-.29 1.16-3.192.797A4.67 4.67 0 002 21.584c0 .23.187.416.417.416h19.166a.418.418 0 00.417-.419 4.674 4.674 0 00-3.548-4.54z"
    />
  </svg>
);

export const IconCashAidReceiver = () => (
  <svg className="icon-menu" viewBox="0 0 24 24">
    <path
      d="M18.214 11.77A5.035 5.035 0 0019.86 8.3a5.049 5.049 0 00-1.272-3.63 4.964 4.964 0 00-6.589-.75 4.969 4.969 0 00-6.957 1.21 5.058 5.058 0 00.74 6.637C3.45 13.004 1.99 15.454 2 18.121v2.159c0 .191.076.375.21.51s.315.21.504.21h18.572c.189 0 .37-.075.504-.21a.726.726 0 00.21-.51v-2.159c.008-2.666-1.452-5.115-3.786-6.35zm-4.94-.508c-.07-.035-.142-.065-.206-.103l.015-.023a5.06 5.06 0 00.764-1.404l.022-.06c.082-.242.145-.49.19-.74l.016-.113a4.628 4.628 0 000-1.546l-.017-.112a5.114 5.114 0 00-.189-.74l-.022-.061a5.061 5.061 0 00-.764-1.404l-.015-.023a3.518 3.518 0 013.695.005 3.613 3.613 0 011.696 3.324 3.598 3.598 0 01-2.135 3.053l-.166.072a3.482 3.482 0 01-.543.171c-.036.008-.071.013-.111.02-.208.04-.418.06-.63.063-.095 0-.19-.006-.285-.015a.536.536 0 01-.11-.007 3.588 3.588 0 01-1.169-.347c-.005-.004-.02-.004-.034-.01h-.001zm7.296 8.298h-4.284v-1.439c0-.225-.013-.45-.033-.67-.006-.064-.016-.129-.024-.193a6.724 6.724 0 00-.112-.672 6.863 6.863 0 00-.119-.476l-.044-.155a7.153 7.153 0 00-.861-1.818l-.028-.04a7.235 7.235 0 00-.393-.534v-.007a6.513 6.513 0 00-.462-.516h.03c.2.026.404.04.607.043h.041c.188-.002.377-.015.563-.038.06-.006.112-.018.175-.027.152-.023.3-.053.444-.09l.128-.033a4.84 4.84 0 00.571-.196c2.281.8 3.808 2.98 3.801 5.424v1.44-.003z"
    />
  </svg>
);

export const IconDashboard = () => (
  <svg className="icon-menu" viewBox="0 0 24 24">
    <path
      d="M9.708 10.333a1.46 1.46 0 011.459 1.46v8.75A1.46 1.46 0 019.707 22h-6.25A1.46 1.46 0 012 20.542v-8.75a1.46 1.46 0 011.458-1.459zm10.834 5A1.46 1.46 0 0122 16.793v3.75A1.46 1.46 0 0120.542 22h-6.25a1.46 1.46 0 01-1.459-1.458v-3.75a1.46 1.46 0 011.46-1.459zm0-13.333A1.46 1.46 0 0122 3.458v8.75a1.46 1.46 0 01-1.458 1.459h-6.25a1.46 1.46 0 01-1.459-1.46v-8.75A1.46 1.46 0 0114.293 2zM9.708 2a1.46 1.46 0 011.459 1.458v3.75a1.46 1.46 0 01-1.46 1.459h-6.25A1.46 1.46 0 012 7.207v-3.75A1.46 1.46 0 013.458 2z"
    />
  </svg>
);

export const IconProductManagement = () => (
  <svg className="icon-menu" viewBox="0 0 24 24">
    <path
      d="M12 2L3 7v10l9 5 9-5V7l-9-5zm.61 10.34v7.967l-.61.34-.61-.34V12.34L4.22 8.357v-.678l.61-.34L12 11.322l2.974-1.652L7.8 5.687l1.222-.679 7.168 3.983 2.974-1.653.61.34v.679l-7.163 3.982z"
    />
  </svg>
);

export const IconSettings = () => (
  <svg className="icon-menu" viewBox="0 0 24 24">
    <path
      d="M21.919 10.659a1.766 1.766 0 00-.776-1.273 1.542 1.542 0 00-1.412-.145c-.398.138-.829-.067-1-.476a8.093 8.093 0 00-.742-1.383.898.898 0 01.111-1.173c.382-.335.599-.84.588-1.368a1.792 1.792 0 00-.652-1.366A9.878 9.878 0 0015.88 2.14 1.564 1.564 0 0015.236 2c-.793-.005-1.472.607-1.603 1.445-.08.449-.473.751-.895.688a6.607 6.607 0 00-1.48 0c-.421.063-.815-.24-.895-.688-.13-.838-.809-1.45-1.602-1.445-.22 0-.44.048-.643.14A9.828 9.828 0 005.96 3.476 1.794 1.794 0 005.31 4.84c-.01.529.206 1.034.588 1.37.322.295.371.81.11 1.17-.29.432-.539.896-.742 1.382-.163.423-.608.634-1.01.476a1.526 1.526 0 00-1.412.144 1.75 1.75 0 00-.765 1.279 11.427 11.427 0 00.002 2.682c.065.528.35.996.776 1.272.425.276.945.33 1.412.145.398-.138.829.067 1 .476.203.487.452.95.742 1.384.26.36.212.876-.111 1.172-.382.335-.599.84-.588 1.368.01.538.25 1.042.652 1.365.664.54 1.388.987 2.156 1.334.204.093.423.14.644.141.793.005 1.472-.607 1.603-1.445.08-.448.474-.75.895-.688.492.055.988.055 1.48 0 .42-.062.814.24.895.688.13.837.81 1.45 1.602 1.445.22 0 .44-.048.643-.14a9.822 9.822 0 002.156-1.335c.401-.323.642-.827.652-1.365a1.774 1.774 0 00-.588-1.37.895.895 0 01-.11-1.17c.29-.432.539-.896.742-1.382.163-.423.608-.634 1.01-.476.18.073.373.111.567.111.82-.006 1.509-.661 1.611-1.533a11.376 11.376 0 00-.003-2.682zM12 15.57c-1.84 0-3.333-1.599-3.333-3.571 0-1.972 1.492-3.571 3.333-3.571 1.84 0 3.333 1.599 3.333 3.571 0 1.972-1.492 3.571-3.333 3.571z"
    />
  </svg>
);

export const IconUserManagement = () => (
  <svg className="icon-menu" viewBox="0 0 24 24">
    <path
      d="M14.889 12.726c.82.46 1.69.824 2.593 1.083.446.144.846.403 1.162.752a9.532 9.532 0 011.35 5.167c.043.92-.156 1.263-.41 1.367-3.128 1.207-12.042 1.207-15.17 0-.25-.1-.452-.442-.408-1.362a9.54 9.54 0 011.351-5.166 2.674 2.674 0 011.163-.753 12.051 12.051 0 002.592-1.082s.375.266.375.246l-.041 2.33 2.242 5.755a.341.341 0 00.627 0l2.243-5.76-.042-2.33zm-3.84 1.07c.298.109.613.166.931.17h.097c.294-.012.586-.06.868-.144l-.835 1.288.757.735-.782 4.793a.085.085 0 01-.167 0l-.783-4.793.76-.74zm-.804-11.504a3.277 3.277 0 012.64-.028 5.026 5.026 0 012.132 1.188c.745.875.92 2.106.45 3.158.038.03.075.064.11.1.159.362.177.772.051 1.148-.041.247-.142.48-.293.68-.28 1.516-1.526 3.427-3.286 3.513-1.384.07-3.106-1.58-3.382-3.513a1.257 1.257 0 01-.28-.616c-.075-.35-.21-.936-.002-1.217a.412.412 0 01.103-.1 3.514 3.514 0 01-.018-2.344 3.319 3.319 0 011.775-1.97z"
    />
  </svg>
);


export const IconMenuEllipsis = () => (
  <svg className="icon menu-ellipsis" viewBox="0 0 24 24">
    <path
      d="M11.761 15.739a1.761 1.761 0 110 3.522 1.761 1.761 0 010-3.522zm0-5.869a1.761 1.761 0 110 3.522 1.761 1.761 0 010-3.522zm0-5.87a1.761 1.761 0 110 3.522 1.761 1.761 0 010-3.522z"
    />
  </svg>
);

export const IconSearch = () => (
  <svg className="icon search" viewBox="0 0 24 24">
    <path
      d="M21.75 20.58l-4.809-4.809a8.421 8.421 0 10-1.18 1.18l4.814 4.799a.833.833 0 101.18-1.175l-.005.005zm-11.326-3.4a6.75 6.75 0 116.75-6.75 6.76 6.76 0 01-6.745 6.75h-.005z"
    />
  </svg>
);
export const IconFileUpload = () => (
  <svg className="icon-menu" viewBox="0 0 24 24">
    <path
      d="M18.754,170.667H6a1.49,1.49,0,0,0-1.455,1.14l0,.008-2.2,7.413a.376.376,0,0,0,.012.246,1.493,1.493,0,0,0,1.391.943H16.5a1.5,1.5,0,0,0,1.439-1.088c0-.008.006-.016.009-.025l2.228-6.674a.368.368,0,0,0,.015-.065,1.456,1.456,0,0,0,.059-.4A1.5,1.5,0,0,0,18.754,170.667z"
    />
  </svg>
);
