/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const parseNumber = (val) => {
  try {
    return parseFloat(val);
  } catch (err) {
    return NaN;
  }
};
const count = (s1, letter) => s1.split(letter).length - 1;

function FormInputPageSize({
  name, onChange, value, total, onSubmit, isLoading, ...rest
}) {
  const handleChange = ({ target }) => {
    const entryValue = target.value;
    const val = parseNumber(entryValue);
    if ((Number.isNaN(val) && !_.isEmpty(val)) || (count(`${entryValue}`, '.') > 1)) return;
    if (entryValue > total) {
      onChange((prev) => ({ ...prev, [name]: total }));
      return;
    }
    onChange((prev) => ({ ...prev, [name]: entryValue.replace(/[^0-9\.]/g, "") })); // eslint-disable-line
  };

  return (
    <div className="form-page-range">
      <small>Range: 1 - </small>
      <div className="input-holder">
        <input
          className="form-control"
          // eslint-disable-next-line no-script-url
          onChange={handleChange}
          value={value || ''}
          {...rest}
        />
      </div>
      {total !== 0 && <small>out of {total}</small>}
      <button
        type="button"
        className={`ml-3 btn btn-secondary btn-sm ${(isLoading || value < 1) && 'disabled'}`}
        onClick={onSubmit}
      >
        {isLoading
          ? <span className="spinner-bar" />
          : 'Filter'}
      </button>
    </div>
  );
}

FormInputPageSize.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,

  onSubmit: PropTypes.func.isRequired,
  total: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isLoading: PropTypes.bool,
};

FormInputPageSize.defaultProps = {
  isLoading: false,
};

export default FormInputPageSize;
