import React from 'react';
import { Link } from 'react-router-dom';

function PageNotFound() {
  return (
    <div className="text-center">
      <div className="error mx-auto" data-text="404">404</div>
      <p className="lead text-gray-800 mb-5">Page Not Found</p>
      <Link to="/">← Back Home</Link>
    </div>
  );
}

export default PageNotFound;
