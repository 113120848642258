import { req } from 'react-reqq';
import _ from 'lodash';
import { ToastSuccess } from 'modules/common/components/toast';
import * as c from './constants';


export const listPayroll = (params, callback) => req.get({
  key: c.LIST_PAYROLL,
  url: '/disbursement-batch',
  params,
  transform: (res) => ({
    list: _.get(res, 'data') || [],
    pager: _.get(res, 'meta.pagination') || {},
  }),
  onSuccess: () => {
    if (!_.isNil(callback)) {
      callback();
    }
  },
});

export const filterPayroll = (keyword, params) => req.get({
  key: c.LIST_PAYROLL,
  url: `/filter-payrollitem/${keyword}`,
  params,
  transform: (res) => ({
    list: (_.get(res, 'data.content') || []).map((item) => ({
      id: item.id,
      attributes: {
        amount: _.get(item, 'amount') || '',
        id: _.get(item, 'id') || '',
        mobile_number: _.get(item, 'mobile_number') || '',
        status_code: _.get(item, 'status_code') || '',
        subscriber_name: _.get(item, 'subscriber_name') || '',
      },
    })),
    pager: _.get(res, 'meta.pagination') || {},
  }),
});


export const viewPayrollItem = (id) => req.get({
  key: c.PAYROLL_ITEM,
  url: `/view-payrollitem/${id}`,
  transform: (res) => {
    const payrollData = _.get(res, 'data') || {};
    return { payrollData };
  },
});

export const updatePayrollItem = (id, payload, callback) => req.post({
  key: c.PAYROLL_ITEM,
  url: `/update-payrollitem/${id}`,
  payload,
  onSuccess: () => {
    ToastSuccess('Successfully Updated!');
    if (!_.isNil(callback)) {
      callback();
    }
  },
});

export const removePayrollItem = (id, callback) => req.remove({
  key: c.PAYROLL_ITEM,
  url: `/delete-payrollitem/${id}`,
  onSuccess: () => {
    ToastSuccess('Successfully Removed!');
    if (!_.isNil(callback)) {
      callback();
    }
  },
});
