import React from 'react';
import PropTypes from 'prop-types';

function SubmitButtonLoading({ isLoading, label, disabled }) {
  return (
    <button
      type="submit"
      className={`ml-3 btn btn-secondary btn-sm ${(isLoading || disabled) && 'disabled'}`}
      disabled={disabled || isLoading}
    >
      {label}
      {isLoading && <span className="ml-2 spinner-bar" />}
    </button>
  );
}

SubmitButtonLoading.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

SubmitButtonLoading.defaultProps = {
  isLoading: false,
  label: 'Submit',
  disabled: false,
};

export default SubmitButtonLoading;
