import React from 'react';
import {
  withRouter, Link, NavLink,
} from 'react-router-dom';
import { useProfile } from 'modules/auth/hooks';
// import PropTypes from 'prop-types';
import Logo from 'assets/img/main-logo.svg';

import { showModal } from 'modules/common/components/AlertModal';
import {
  IconDashboard,
  IconProductManagement,
  IconCashAidReceiver,
  IconContentManagement,
  IconSettings,
} from './Icons';
import LogoutAlert from './LogoutAlert';

const MENU_MAKER = [
  {
    url: '/file-uploaded',
    label: 'File Uploaded',
    icon: <IconProductManagement />,
  },
  {
    url: '/cash-aid-receivers',
    label: 'Cash Aid Receivers',
    icon: <IconCashAidReceiver />,
  },
  {
    url: '/disbursement-transactions',
    label: 'Disbursement Transactions',
    icon: <IconContentManagement />,
  },
  // {
  //   url: '/cash-disbursement',
  //   label: 'Cash Disbursement Demo',
  //   icon: <IconContentManagement />,
  // },
  {
    url: '/settings',
    label: 'Settings',
    icon: <IconSettings />,
  },
];

const MENU_APPROVER = [
  {
    url: '/transaction-list',
    label: 'Transaction List',
    icon: <IconContentManagement />,
  },
];

function Sidebar() {
  const profile = useProfile();
  const full_name = `${profile.first_name} ${profile.last_name}`;

  const handleLogout = (e) => {
    e.preventDefault();
    showModal({
      title: false,
      content: (close) => <LogoutAlert onClose={close} />,
    });
  };

  const renderTabs = () => {
    const renderTab = (item) => (
      <NavLink
        key={item.label}
        className="nav-item nav-link menu-item"
        to={item.url}
      >
        {item.icon}
        <span className="text">{item.label}</span>
      </NavLink>
    );
    if (profile.user_type === 'MAKER') {
      return MENU_MAKER.map((item) => renderTab(item));
    }
    return MENU_APPROVER.map((item) => renderTab(item));
  };

  return (
    <div className="side-bar-container">
      <div className="sidebar d-flex flex-column">
        <div className="sidebar-brand">
          <Link to="/">
            <img className="logo-brand" src={Logo} alt="AppendPay" />
          </Link>
        </div>
        <nav className="navbar-nav accordion menu-list">

          <NavLink exact className="nav-item nav-item nav-link menu-item" to="/">
            <IconDashboard />
            <span className="text">Dashboard</span>
          </NavLink>
          {renderTabs()}
        </nav>
        <div className="sidebar-account mt-auto d-flex flex-row align-items-center">
          <div className="photo">
            <img className="rounded-circle thumbnail" src="https://via.placeholder.com/48?text=JD" alt="Juan Dela Cruz" />
          </div>
          <div className="details">
            <p className="user-name">
              {full_name}
              <span className="circle success ml-2 d-inline-block" />
            </p>
            <a className="text-danger text-logout" href="/logout" onClick={handleLogout}>
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Sidebar);
