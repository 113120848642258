import { req } from 'react-reqq';
import { history } from 'index';
import _ from 'lodash';
import { ToastSuccess } from 'modules/common/components/toast';
import * as c from './constants';

export const checkAuth = () => {
  const token = sessionStorage.getItem('token');
  const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');
  const modules = JSON.parse(sessionStorage.getItem('access-modules') || '[]');
  const permissions = JSON.parse(sessionStorage.getItem('access-perms') || '[]');
  const isAuthenticated = !!token;
  const data = {
    isAuthenticated,
    profile,
    permissions,
    modules,
  };
  req.set(c.AUTHENTICATION, data);
  return { type: 'CHECK_AUTH' };
};

export const login = (payload) => req.post({
  key: c.LOGIN,
  url: '/userlogin',
  payload,
  onSuccess: (res) => {
    ToastSuccess('Logged In!');
    sessionStorage.setItem('token', _.get(res, 'response.token'));

    const profileData = _.get(res, 'response.data');
    sessionStorage.setItem('profile', JSON.stringify(profileData));

    const p = {
      ...res.response.data,
    };
    req.set(c.AUTHENTICATION, {
      isAuthenticated: true,
      profile: p,
    });
    history.push('/');
  },
});

export const logout = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('profile');
  sessionStorage.removeItem('access-perms');
  sessionStorage.removeItem('access-modules');
  localStorage.clear();
  req.reset();
  req.set(c.AUTHENTICATION, { isAuthenticated: false });
  history.push('/');
  return { type: 'LOGOUT' };
};

export const changePassword = (payload, onSuccess) => req.put({
  key: c.CHANGE_PASSWORD,
  url: '/api/admin/profile/update_password',
  payload,
  onSuccess: (res) => {
    ToastSuccess('Password Updated!');
    if (onSuccess) onSuccess(res);
  },
});
